@import 'partials/partials';

.subscription{
    padding: 50px 0px;
    // min-height: 100vh;
    @media($sm-991){
        padding: 100px 0px;
    }

    @media($sm-767){
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0px;
    }
    .subscription-box{

 
.custom-checkbox-list {
    list-style-type: none;
    padding: 0;
  }
  .current-plan{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 400;
    letter-spacing: 0px;
    color: #70C044;
    font-size: 18px;

    @media($sm-1679){
        font-size: 16px;
    }
    @media($sm-991){
        font-size: 14px;
    }
    .current-checkbox{
        margin-right: 10px;
    }
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  .custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
  
  .custom-checkbox .checkmark {
    width: 13px;
    height: 13px;
    background-color: #eee;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  .custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  

  .custom-checkbox input:checked ~ .checkmark {
   
    background: #4B4B4B 0% 0% no-repeat padding-box;
  }
  
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
        .box-sec{
            display: flex;
            justify-content: space-between;
            padding-top: 50px;
            @media($sm-1679){
                padding-top: 40px;  
            }
            @media($sm-1441){
                padding-top: 30px;  
                 
            }
            @media($sm-1199){
                padding-top: 20px;  
                 
            }
            
            @media($sm-991){
                flex-direction: column;
            }
        }
        .text-inner{
            width: 30%;
            @media($sm-1441){
                width: 26%;
            }
            
            @media($sm-991){
                width: 100%;
            }
           
        }
        .icon{
            background: transparent linear-gradient(180deg, #292929 0%, #1D1D1D 100%) 0% 0% no-repeat padding-box;
            border: 1px solid #4C4C4C;
            width: 37px;
            height: 37px;
            width: 77px;
            height: 77px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            margin-right: 20px;
            flex-shrink: 0;
            @media($sm-1679){
                width: 64px;
                height: 64px;
            }
            @media($sm-1441){
                width: 56px;
                height: 56px; 
                margin-right: 15px;  
            }
            @media($sm-1281){
                width: 48px;
                height: 48px;
            }
            @media($sm-1199){
                width: 40px;
                height: 40px;
            }
            img{
                width: 50%;
                object-fit: contain;
            }
        }
        .price-sec{
            width: 40%;
            display: flex;
            flex-direction: column;
            padding-left: 50px;
            @media($sm-1679){
                padding-left: 40px;
            }
            @media($sm-1441){
                padding-left: 30px; 
                width: 34%;
            }
            @media($sm-1199){
                padding-left: 20px;
            }
            @media($sm-991){
                width: 100%;
                padding-left: 0px !important;
                padding: 30px 0px;
            }
            &.border-start{
                border-left:1px solid #292929 !important;
                @media($sm-991){
                 border: none !important;
                }
            }
            .pricing{
                display: flex;
                color: #fff;
                font-size: 20px;
                line-height: 1;
                font-weight: 400;
                @media($sm-1679){
                     font-size: 17px;
                }
                @media($sm-1441){
                    font-size: 14px;
                }
                @media($sm-1199){
                    font-size: 11px;
                }
                @media($sm-767){
                     
                }
                .dollar{
                    padding-top: 0.75em;
                    padding-right: 13px;
                }
                .price{
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    font-size:71px;
                    // line-height: 85px;
                    font-weight: 700;
                    @media($sm-1679){
                        font-size: 61px;
                    }
                    @media($sm-1441){
                        font-size: 41px;
                    }
                    @media($sm-1199){
                        font-size: 31px;
                    }
                    @media($sm-767){
                        font-size: 31px;
                    }
                }
                .cycle{
                    align-self: flex-end;
                    padding-bottom: 0.25em;
                }
            }
            .list-items{
                padding-top: 40px;
                @media($sm-1679){
                    padding-top: 30px;
                }
                @media($sm-1441){
                    padding-top: 20px;
                }
               
                ul{
                    display: flex;
                    align-items: center;
                    margin-bottom: 0px !important;
                    flex-wrap: wrap;
                    li{
                         
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        font-size: 14px;
                        font-weight: 300;
                        padding: 0px 20px;
                    }
                }
              
            }
        }
        .cancel-btn{
            flex: 1;
            text-align: right;

            .btn-cancel{
                background: #1D1D1D 0% 0% no-repeat padding-box;
                border: 1px solid #292929;
                border-radius: 50px;
                width: 100%;
                letter-spacing: 0px;
                color: #F42F2F;
              
                font-size: 15px;
            }
        }
        h3{
   
            letter-spacing: 0px;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 23px;
            line-height: 35px;
        }
        .box-inner{
            background: #121212 0% 0% no-repeat padding-box;
            border: 1px solid #292929;
            border-radius: 24px;
            padding: 35px 80px 80px 80px;
            @media($sm-1679){
                padding: 30px 60px 60px 60px;
            }
            @media($sm-1441){
                padding: 22px 44px 44px 44px;
            }
            @media($sm-1281){
                padding: 16px 30px 24px 30px;
            }
            @media($sm-1199){
                padding: 16px 24px 24px 24px;
            }
            
            @media($sm-991){
                
            }
        }
        .detail-sec{
            h4{
                letter-spacing: 0px;
                color: #FFFFFF;
                font-size: 26px;
                line-height: 1.2;
                font-weight: 700;
                @media ($sm-1679){
                    font-size: 22px;
                }
                @media ($sm-1441){
                    font-size: 20px;
                }
                @media ($sm-1281){
                    font-size: 18px;
                }
                @media ($sm-1199){
                    font-size: 16px;
                }
            }
            .text-sec{
            
                letter-spacing: 0px;
                color: #C6C6C6;
                font-size: 18px;
                line-height: 1.1667;
                font-weight: 400;
                margin-bottom: 0;
                @media ($sm-1679){
                    font-size: 16px; 
                }
                @media ($sm-1441){
                    font-size: 14px;
                }
                @media ($sm-1199){
                    font-size: 12px;
                }
                .nmbr{
                    color:#F42F2F;
                    font-weight: 600;
                }
            }
        }
    }
}

.subscription-header{
    &.header .navbar .nav-link {
        margin-left: 0px;
        display: flex;
        align-items: center;
        .ico1{
            margin-right: 10px;
        }
        &.active{
            background-color: transparent;
            background: transparent;
            border: none;
            box-shadow: none;
        }
        &:hover{
            color: #F42F2F;
            .ico1{
                img{
                    filter: invert(44%) sepia(98%) saturate(5335%) hue-rotate(345deg) brightness(99%) contrast(93%);
                }
            }
          
        }
    }
    .nav-item{
        &:hover{
            background-color: transparent;
        }
    }
    .navbar-nav{
        
        &:not(.settings-nav){
            @media ($sm-991){
                margin-top: 20px;
                .nav-item{
                    padding-left: 20px;
                }
                .nav-link{
                     padding: 16px;
                }
            }
        }
    }
    .settings-nav{
        @media ($sm-991){
            position: absolute;
            top: 30px;
            right: 80px;
        }
        .dropdown-menu{
            @media ($sm-991){
                position: absolute;
            }
        }
    }
    .nav-inner{
        background: #171717 0% 0% no-repeat padding-box;
        border-radius: 26px;
        min-height: 54px;
        padding: 0px 10px;
        position: relative;
        z-index: 9999;
        .nav-item{
            margin-right: 110px;
            @media ($sm-991){
                margin-right: 0;
            }
        }
    }
    .settings-ico{
        width: 45px;
        height: 45px;
        background-color: #F42F2F;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media($sm-1441){
            width: 40px;
            height: 40px;
        }
        @media($sm-767){
            width: 35px;
            height: 35px;
        }
        img{
            width: 20px;
            height: 20px;
            @media($sm-1441){
                width: 16px;
                height: 16px;
            }
            @media($sm-767){
                width: 15px;
                height: 15px;
            }
        }
    }
    .settings-items{
        background: #0A0A0A 0% 0% no-repeat padding-box;
        border: 1px solid #262626;
        border-radius: 18px;
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 20px 20px 20px 20px;
        width: 226px;
        @media($sm-1679){
            padding: 10px 15px; 
             
        }
        li{
            // padding-bottom: 15px;
            padding: 0;
            &:hover{
                background-color: #f42f2f;
                border-radius: 50px;
            }
            a{ 
                width: 100%;
                padding: 10px 40px 10px 10px;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #fff;
                font-size:$primaryFont;
                font-weight: 500;
                margin-left: 10px;
                font-size: 16px;
                @media ($sm-1679){
                    // margin-left: 40px;
                    padding: 8px 40px 8px 8px;
                    font-size: 14px;
                }
                @media ($sm-1441){
                    font-size: 15px;
                    margin-left: 20px;
                }
                @media ($sm-1199){
                    font-size: 14px;
                    line-height: 18px;
                    margin-left: 20px;
                }
                @media ($sm-991){
                    margin-left: 0px;
                    padding: 10px 0;
                }

            }
            .ico2{
                margin-right: 14px;    
            }
        }
    }
}

.contact-admin{
padding-top: 10px;
width: 30%;
@media($sm-1199){
    width: 34%;
}
@media($sm-991){
    width: 100%;
}
.right-sec{
    display: flex;
    padding: 10px 0px;
    .text{
        font-weight: 400;
        font-size: 16px;
        // font-family: $font-secondary;
        // color: var(--txtColor);
        color: #fff;
        // padding: 10px;
         @media ($sm-1679){
            font-size: 14px;
        }  
    }
    .subtitle{
        padding-right: 10px;
    }
    .ico{
        margin-right: 6px;
    }
    a{
        font-weight: 600;
        font-size: 14px;
        // font-family: $font-secondary;
        // color: var(--txtColor);
        color:#fff;
        cursor: pointer;
        // padding: 10px;
         @media ($sm-1679){
            font-size: 12px;
        } 
    }
}
}
.subscription .subscription-box .price-sec .list-items ul li {
                display: flex;
                padding-bottom: 10px;
                .txt-inner{
                    text-align: left;
                    flex: 1;
                    padding-left: 10px;
                    margin-top: -6px;
                    .txt{
                        padding-left: 0px;
                        &.title{
                            font-size: 13px;
                            line-height: 23px;
                        }
                    }
                }
                .ico{
                    img{
                        width: 16px;
                        height: 16px;  
                    }
                }
            }
