@import 'partials/partials';
.header{
    display: flex;
    flex-direction: column;

    @media($sm-991){
        position: fixed;
        width: 100%;
        top: 0;
        background: #111;
        z-index: 9;
    }
    .navbar{
        padding-top: 60px;
        padding-bottom: 24px;
        transition: all 0.3s ease-in;
        @media ($sm-1679){
            padding-top: 50px;
            padding-bottom: 19px;
        }
        @media ($sm-1441){
            padding-top: 40px;
            padding-bottom: 19px;
        }
        @media ($sm-1199){
            padding-top: 30px;
            padding-bottom: 0px;
        }
        @media ($sm-991){
            background: $bodyBg;
            margin-top: 0px;
        }
        @media($sm-767){
            // position: absolute;
            width: 100%;
            background-color: #0d0d0d;
            padding-top: 21px;
        }
        .navbar-nav{
            align-items: center;
            @media ($sm-991){
                align-items: flex-start;
            }
        }
        .navbar-brand{
            // font-size: clamp(1.25rem, 2vw, 2.625rem);
            img{
                max-height: 39px;
            }
        }
        .nav-link{
            font-size:$primaryFont;
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            margin-left: 50px;
            color: #fff;
            position: relative;
            &.active, &:hover{
                color: $primary;
            }
            @media ($sm-1679){
                margin-left: 40px;
            }
            @media ($sm-1441){
                font-size: 15px;
                margin-left: 30px;
            }
            @media ($sm-1199){
                font-size: 14px;
                line-height: 18px;
                margin-left: 20px;
            }
            @media ($sm-991){
                margin-left: 0px;
                padding: 10px 0;
            }
        }
        &.bg-color{
            background: $bodyBg;
            padding-top: 20px;
        }
        .btn-signup{
            margin-left: 50px;
            border-radius: 26px;
            padding: 13px 40px 14px;
            font-size: 16px;
            line-height: 1;
            min-height: unset;
            @media ($sm-1679){
                margin-left: 40px;
            }
            @media ($sm-1441){
                margin-left: 30px;
            }
            @media ($sm-1199){
                margin-left: 20px;
            }
            @media ($sm-991){
                margin-left: 0px;
                margin-top: 15px;
            }
        }
    }
    /* mobile menu toggle button */
.navbar-toggler {
	position: relative;
	width: 30px;
	height: 20px;
	margin: 0;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
}
.navbar-toggler span {
	margin: 0 auto;
	position: relative;
	top: 0px;
	transition-duration: 0s;
	transition-delay: .2s;
  transition: background-color 0.3s;
}
.navbar-toggler span:before, .navbar-toggler span:after {
	position: absolute;
	content: '';
}
.navbar-toggler span, .navbar-toggler span:before, .navbar-toggler span:after {
	width: 100%;
	height: 3px;
	background-color: #Fff;
	display: block;
  opacity: 1;
}
.navbar-toggler span:before {
	margin-top: -8px;
	transition-property: margin, transform;
	transition-duration: .2s;
	transition-delay: .2s, 0;  
}
.navbar-toggler span:after {
	margin-top: 8px;
	transition-property: margin, transform;
	transition-duration: .2s;
	transition-delay: .2s, 0;  
}

.menu-toggle-active span {
  background-color: transparent;
	transition: 0.3s background-color;
}
.menu-toggle-active span:before {
	margin-top: 0;
	transform: rotate(45deg);
	transition-delay: 0, .2s;
}
.menu-toggle-active span:after {
	margin-top: 0;
	transform: rotate(-45deg);
	transition-delay: 0, .2s;
}
}