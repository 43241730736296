@import 'partials/partials';
.footer{
    display: flex;
    flex-direction: column;
    border-top: 1px solid $lightBorder;
    .footer-copy{
        padding-top: 24px;
        padding-bottom: 32px;
        margin: 0;
        font-size: 16px;
        font-weight: 300;
        color: $footerTxt;
        text-align: center;
        @media ($sm-1679){
            font-size: 14px;
        }
        @media ($sm-1441){
            font-size: 13px;
        }
    }
}