
$primary: #F42F2F;
$secondary: #fff;
$dark:#000;
$bodyBg:#0D0D0D;
$lightBorder:#ffffff1e;
$white:#fff;
$footerTxt:#F1F1F1;
$fadeBorder:rgba(255,255,255,0.15);
$fadePrimary:#4B1A1A;
$grey:#585858;
$signupFormBorder:#222;
$darkInputBg:#00000080;

// fonts
$primaryFont: "Barlow", sans-serif;



 