@import 'partials/partials';

.modal-small-width{
    .btn-close{
        
            position: absolute;
            right: 26px;
            top: 26px;
        
    }
    .diamond{
        margin-right: 10px;
    }
    .modal-dialog{
        // max-width: 755px;
        .modal-content{
            
                background-image: url(../images/lines.svg);
                background-repeat: no-repeat;
                background-position: top left;
                background-color: #000;
            
            border-radius: 32px;
            @media ($sm-1679){
                border-radius: 28px;
            }
            @media ($sm-1441){
                border-radius: 22px;
            }
            .modal-title{
                color: #fff;
                text-align: center;
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                padding-bottom: 14px;
                @media ($sm-1679){
                    font-size: 16px;
                    line-height: 1.167;
                }
                @media ($sm-1441){
                    font-size: 14px;
                }
                @media ($sm-1199){
                    font-size: 13px;
                }
            }
            .modal-desc{
                color: #fff;
                font-size: 13px;
                line-height: 24px;
                text-align: center;
               
            }
           
            .modal-header{
                padding: 43px 52px 0;
                border-bottom: none;
                @media ($sm-1679){
                    padding: 36px 48px 0;
                }
                @media ($sm-1441){
                    padding: 30px 42px 0;
                }
                @media ($sm-1199){
                    padding: 20px 30px 0;   
                }
                @media ($sm-767){
                    padding: 20px 20px 0;   
                }
                .modal-title{
                

                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-transform: capitalize;
            
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 700;
                    @media ($sm-1679){
                        font-size: 16px;
                        line-height: 1.167;
                    }
                    @media ($sm-1441){
                        font-size: 14px;
                    }
                    @media ($sm-1199){
                        font-size: 13px;
                    }
                }
                
            }

            .modal-body{
                padding: 23px 52px 0px;
                @media ($sm-1679){
                    padding: 20px 48px 10px;
                }
                @media ($sm-1441){
                    padding: 18px 42px 10px;
                }
                @media ($sm-1199){
                    padding: 18px 30px 10px;   
                }
                @media ($sm-767){
                    padding: 18px 20px 10px;   
                }
                
                &.modal-form{
                .input-wrp{
                    margin-bottom: 20px !important;
                    @media($sm-1281){
                        margin-bottom: 0px !important;  
                    }
                }
                .form-label{
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 8px;
                    @media ($sm-1679){
                        margin-bottom: 4px;
                        font-size: 13px;
                    }
                    @media ($sm-1441){
                        margin-bottom: 2px;
                        font-size: 12px;
                    }
                    @media ($sm-1199){
                        margin-bottom: 0; 
                    } 
                }
                .form-control{
                    font-weight: 600;
                  
                }
                .form-group{
                    margin-bottom: 0px; 
                    position: relative;
                    // @media ($sm-1679){
                    //     margin-bottom: 26px; 
                    // }
                    // @media ($sm-1441){
                    //     margin-bottom: 20px; 
                    // }
                    // @media ($sm-1199){
                    //     margin-bottom: 18px; 
                    // }
                }
              
            
            }
            }
            .modal-footer{
                padding: 0px 52px 100px;
                @media ($sm-1679){
                    padding: 0 48px 36px;
                }
                @media ($sm-1441){
                    padding: 0 42px 30px;
                }
                @media ($sm-1199){
                    padding: 0 30px 20px;   
                }
                @media ($sm-767){
                    padding: 0 20px 20px;   
                }
                border-top: none;
                .btn{
                    border: none;
                  
                }
            }
        }
    }

    &.forgot-password{
        .modal-dialog{
            max-width: 546px;
            margin-left: auto;
            margin-right: auto;
          
            @media ($sm-1679){
                max-width: 536px;
            }
            @media ($sm-1441){
                max-width: 526px;
            }
        }
    }
    &.modal-micro{

        &.forgot-modal{
            .modal-dialog{
                max-width: 546px;
                margin-left: auto;
                margin-right: auto;
              
                @media ($sm-1679){
                    max-width: 480px;
                }
                @media ($sm-1441){
                    max-width: 440px;
                }
            }    
        }
        .modal-body{
            // border-bottom: 1px dashed #222222;
        }
        .modal-dialog{
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
          
            @media ($sm-1679){
                max-width: 440px;
            }
            @media ($sm-1441){
                max-width: 400px;
            }
             
            .modal-content{
                // background-image: url(../img/modal-bg.png);
                // background-repeat: no-repeat;
                // background-size: cover;
                .modal-footer{
                    justify-content: center;
                    .btn{
                        flex: 1;
                        max-width: 100%;
                        width: 251px;
                        height: 52px;
                        background: #F42F2F 0% 0% no-repeat padding-box;
                        border-radius: 30px;
                        color: #fff;
                        line-height: unset;
                        font-weight: 400;
                        font-size: 20px;
                        @media($sm-1679){
                            height: 44px;
                            // min-height: 44px;
                            font-size: 18px;
                        }
                        @media($sm-1441){
                            height: 42px;
                            // min-height: 42px;
                            font-size: 16px;
                        }
                        @media($sm-1199){
                            font-size: 14px;
                        }
                    }
                }

                .success-ico{ 
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        max-width: 180px;
                        @media ($sm-1679){
                            max-width: 160px;
                        }
                        @media ($sm-1441){
                            max-width: 140px;  
                        }
                        @media ($sm-1199){
                            max-width: 100px;
                        }
                    }
                }
            }
        }

    }
.for-gottext{
    
    margin-bottom: 30px;
    position: absolute;
    color: #ffff;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
}
}


.modal-backdrop {
    background: #26491980 0% 0% no-repeat padding-box;
}

.footer-table{
    width: 100%;
    font-feature-settings: 'lnum' 1;
    td{
        border: none;
        background-color: #000;
        color: #fff
    }
    .transctn{
        // font: normal normal normal 14px/24px Barlow;
        letter-spacing: 0px;
        color: #6F6F6F;
        font-size: 14px;
        font-weight: 400;
    }
    .val{
        font-weight: 600;
        color: #fff;
        font-size: 14px;
    }
}

.modal-subscribe-success{
    .modal-body{
        padding: 43px 52px 35px !important;
        @media ($sm-1679){
            padding: 36px 48px 10px !important;
        }
        @media ($sm-1441){
            padding: 30px 42px 10px !important;
        }
        @media ($sm-1199){
            padding: 20px 30px 10px !important;   
        }
        @media ($sm-767){
            padding: 20px 20px 10px !important;   
        }
    }
    .modal-footer{
        padding: 34px 52px 40px !important;
        @media ($sm-1679){
            padding: 36px 48px 10px !important;
        }
        @media ($sm-1441){
            padding: 30px 42px 10px !important;
        }
        @media ($sm-1199){
            padding: 20px 30px 10px !important;   
        }
        @media ($sm-767){
            padding: 20px 20px 10px !important;   
        }
        border-top: none;
        .btn{
            border: none;
          
        }
    }
    .plan-head{
        background: transparent linear-gradient(180deg, #121212 0%, #000000 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #1D1D1D;
        border-radius: 22px;
        padding: 29px 43px;
        @media ($sm-1679){
            padding: 16px;
            border-radius: 20px;
        }
        @media ($sm-1441){
            border-radius: 18px;
        }
        .service{
            color: #fff;
        }
        .descriptn{
            color: #fff;
       
            font-size: 12px;
            line-height: 23px;
            font-weight: 300;
        }
        .rate{
            color: #fff;
            font-size: 23px;
            line-height: 63px;
            font-weight: 600;
        }
        
    }


  
.modal-content{
    background-image: url(../images/lines.svg);
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #000;
}
.modal-body{
    border-bottom: 1px dashed #222222;
}

.modal-title,
.modal-desc{
    text-align: center;
}

.modal-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    @media ($sm-1679){
        font-size: 16px;
        line-height: 1.167;
    }
    @media ($sm-1441){
        font-size: 14px;
    }
    @media ($sm-1199){
        font-size: 13px;
    }
    
}

.footer-table{
    width: 100%;
    font-feature-settings: 'lnum' 1;
    td{
        border: none;
        background-color: #000;
        color: #fff
    }
    .transctn{
        // font: normal normal normal 14px/24px Barlow;
        letter-spacing: 0px;
        color: #6F6F6F;
        font-size: 14px;
        font-weight: 400;
    }
    .val{
        font-weight: 600;
        color: #fff;
        font-size: 14px;
    }
}
} 

.modal-small-width{
    &.modal-micro{
        &.activation-modal-signup{
            .modal-dialog{
            max-width: 640px;

            .modal-body {
                overflow: unset !important;
            }
        }
            .modal-content .modal-footer .btn {
                flex:unset;
                width: 184px;
                min-height: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;            
        }
        .modal-footer{
            justify-content: flex-end !important;
            padding: 50px;
        }
    }
}   
}