@import 'partials/partials'; 

.section-login{
    background-color: #0D0D0D;
    padding-top: 120px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 82px);
    @media ($sm-1679){
        padding-top: 100px;
        padding-bottom: 40px;
    }
    @media ($sm-1441){
        padding-top: 80px;
        padding-bottom: 30px;
    }
    @media ($sm-1281){
        padding-top: 60px;
    }
    .login-form-wrp{
        max-width: 530px;
        margin-left: auto;
        margin-right: auto;
        @media ($sm-1679){
            max-width: 480px;
        }
        @media ($sm-1441){
            max-width: 440px;
        }
        @media ($sm-1281){
            max-width: 400px;
        }
    }

    .login-title{
        color: #ffffff;
        margin-bottom: 48px;
        font-size: 24px;
        @media ($sm-1679){
            margin-bottom: 36px;
            font-size: 20px;
        }
        @media ($sm-1441){
            margin-bottom: 20px;
            font-size: 18px;
        }
         
    }

    .sep-wrp{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 20px;
        margin-top: 20px;
        &:before,
        &:after{
            display: block;
            content: '';
            height: 1px;
            background-color: #FFFFFF;
            flex: 1;
            opacity: 0.2;
        }
        
        .sep-title{
            margin-bottom: 0;
            font-size: 14px;
           
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .btn-google-login{
        color: #ffffff !important;
        border: 1px solid #333333 !important;
        border-radius: 30px;
        background-color: #171717 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        .ico{
            img{
                width: 22px;
                object-fit: contain;
            }
        }
        .txt{
            padding-left: 24px;
        }
    }

    .login-form{ 
        .input-wrp{
            &:last-child{
                margin-bottom: 0;
            }
            label.label{
                margin-bottom: 3px;
                font-size: 16px;
                @media ($sm-1679){
                    font-size: 14px;
                }
                @media ($sm-1441){
                    font-size: 13px;
                }
            }
            &.reset-pw-conf{
                padding-top: 30px;
            }
        }
         
         
        .form-wrp{
            height: 100%;
            overflow: auto;
        }
       
    }

    .remember-wrp{
        display: flex;
        align-items: center;
        .forgot-link{
            margin-left: auto;
            text-decoration: none;
            color: #ffffff;
            font-size: 16px;
            @media ($sm-1679){
                font-size: 14px;
            }
            @media ($sm-1441){
                font-size: 13px;
            }
        }
         
    }

    .signup-txt,
    .login-txt{
 
        font-size: 16px;
        margin-bottom: 0;
        @media ($sm-1679){
            font-size: 14px;
        }
        @media ($sm-1441){
            font-size: 13px;
        }
        @media ($sm-1199){
            font-size: 12px;
        } 
    }
    .signup-txt{
        text-align: center;
        color: #ffffff;
    }
}

 

  