:root {
	background: #222;
	--card-red: #b02822;
	--card-black: #313236;
	--card-blue: #2e6fea;
	--card-green: #4da208;
}

* {
	font-family: "Open Sans", sans-serif, system-ui;
	box-sizing: border-box;
}

body,
button,
div,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
li,
textarea,
ul,
Link {
	margin: 0;
	padding: 0;
}

body,
html {
	min-height: 100%;
}
body::-webkit-scrollbar {
	display: none;
}

#body-container {
	min-height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.global-nav {
	height: 45px;
	position: fixed;
	z-index: 5000;
	top: 0;
	width: 100%;
	background-color: #0e1217;
	text-rendering: optimizeLegibility;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}
.global-nav:after {
	content: "";
	background: maroon;
	position: absolute;
	bottom: 0;
	right: 0;
	height: 1px;
	width: 85%;
}
.nav-item {
	/* color: rgb(239, 239, 239);
	padding: 10px;
	border-radius: 1px;
	text-decoration: none;
	font-size: 0.85em;
	transition: 0.25s ease all; */
}
.nav-item:hover {
	background: #3f444d;
	cursor: pointer;
}

.nav-heading {
	color: rgb(239, 239, 239);
	margin: 10px 20px 10px 0px;
}
.nav-heading:hover {
	cursor: pointer;
}
.nav-heading:before {
	display: inline-block;
	height: 30px;
	border-left: 1px solid maroon;
	position: relative;
	margin: 0 1vw;
	content: "";
	vertical-align: middle;
}

.sidebar {
	/* background: rgba(120, 0, 0, 0.35); */
	background: rgb(125, 125, 125);
	position: fixed;
	top: 45px;
	margin: 0;
	bottom: 0;
	/* max-width: 300px; */
	width: 12.5%;
	float: left;
}

.sidebar .sidebar-icon {
	display: none;
}

.sidebar-button {
	background-color: rgb(239, 239, 239);
	/* border-top-right-radius: 2px;
	border-bottom-right-radius: 2px; */
	cursor: pointer;
	color: rgb(0, 0, 0);
	width: 100%;
	z-index: 5001;
	padding: 8px;
	margin: 0px 1px 3px 0px;
	-webkit-box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 1px 2px 2px 1px rgba(120, 0, 0, 0.1);
	font-size: 1em;
	border: 1px solid rgb(50, 50, 50);
	/* border-top: 1px solid rgb(50, 50, 50);
	border-left: 1px solid rgb(50, 50, 50);
	border-right: 1px solid rgb(50, 50, 50);
	border-bottom: 1px solid rgb(50, 50, 50); */
	transition: 0.2s ease all;
}

.sidebar-button:hover:not(.active) {
	background: rgb(210, 210, 210);
	/* background-color: rgb(203, 85, 85); */
	transform: translate(3px, 0px);
}

.sidebar-link {
	text-decoration: none;
	color: #111;
}

.active {
	-webkit-box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
	box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
	background: rgb(100, 100, 100);
	border-top: 1px solid rgb(128, 00, 00, 0.35);
	/* border-bottom: 1px solid rgb(128, 00, 00, 0.35); */
}

.expanded {
	height: 80%;
	vertical-align: top;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.relative-center {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#splash-ui {
	height: 200px;
	border: 1px solid black;
	padding: 0px 5px 5px 5px;
	background-color: #111;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#splash-ui h4 {
	color: white;
	margin: 0px;
}

.splash-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* filter: contrast(170%) brightness(1000%); */
}

.splash-overlay {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.splash-top-cnt {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	padding: 1rem 2rem;
	background-color: #111;
	position: fixed;
	top: 0;
	transition: 0.3s ease all;
	z-index: 999;
}

.splash-top-text {
	color: rgba(240, 240, 240, 1);
	font-size: 1.4rem;
	margin: auto 0px;
}
.splash-header {
	color: rgba(240, 240, 240, 1);
	padding: 25vh 20px 5vh 20px;
	width: 100%;
	text-align: center;
	text-shadow: 1px 1px #222;
	font-size: 3rem;
}
.splash-subheader {
	color: rgba(225, 225, 225, 1);
	padding: 5vh 20px 15vh 20px;
	width: 100%;
	text-align: center;
	text-shadow: 1px 1px #222;
}
.right-sec{
	display: flex;
}
.right-sec a{
	text-decoration: none;
}
.splash-btn-cnt {
	position: absolute;
	top: 55%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.splash-btn {
	padding: 0.5rem 1.5rem;
	margin: 0px 1rem;
	border-radius: 4px;
	border: none;
	cursor: pointer;
}
.splash-btn:hover {
	filter: brightness(80%);
}
.splash-btn.signup {
	color: white;
	background-color: maroon;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.splash-section {
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.splash-btn.big {
	padding: 1rem 3rem;
}

.splash-dark {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url(images/noise.svg);
}
.splash-red {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background: linear-gradient(15deg, rgba(128, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url(images/noise.svg);
}

.splash-content-cnt {
	padding: 1.5rem 1.5rem;
	margin: 1.5rem auto;
	display: flex;
	flex-direction: row;
	width: 90%;
	height: 55vh;
	background-color: #222;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
}
.splash-text-cnt {
	margin: auto 10px;
	font-size: 1rem;
	color: white;
}
.splash-body {
	max-width: 40vw;
	color: white;
}

.splash-drd {
	width: clamp(360px, 40vw, 80vw);
	height: auto;
	max-height: 400px;
	max-height: 100%;
	object-fit: scale-down;
	margin: auto;
}
.splash-drt {
	width: clamp(360px, 40vw, 80vw);
	height: auto;
	max-height: 400px;
	max-height: 100%;
	object-fit: scale-down;
	margin: auto;
}

.splash-1 {
	margin-top: -10vh;
}
.splash-2 {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}
.splash-3 {
	margin-top: 10vh;
}
.splash-col {
	position: absolute;
	top: 85vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.splash-cta {
	margin-top: 40px;
	margin-bottom: 20px;
}

@media (max-width: 800px) {
	.splash-content-cnt {
		flex-direction: column;
		width: 100%;
		height: auto;
	}
	.splash-text-cnt {
		text-align: center;
		width: 100%;
		margin: 20px auto 0 auto;
	}
	.splash-body {
		max-width: 100%;
	}
	.mobile-flip {
		display: flex;
		flex-direction: column-reverse;
	}
	.splash-section {
		min-height: 110vh;
		max-height: 110vh;
	}
}

.verification-req {
	color: white;
	text-align: center;
}

@keyframes fadeInAndOut {
	0% {
		opacity: 0.01;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.01;
	}
}
@-o-keyframes fadeInAndOut {
	0% {
		opacity: 0.01;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.01;
	}
}
@-moz-keyframes fadeInAndOut {
	0% {
		opacity: 0.01;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.01;
	}
}
@-webkit-keyframes fadeInAndOut {
	0% {
		opacity: 0.01;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.01;
	}
}

#splash-logo {
	width: 50%;
	max-width: 600px;
	animation: fadeInAndOut 4s infinite;
	-webkit-animation: fadeInAndOut 4s infinite;
	-moz-animation: fadeInAndOut 4s infinite;
	-o-animation: fadeInAndOut 4s infinite;
	-ms-animation: fadeInAndOut 4s infinite;
}

.no-pulse {
	transition: 3s ease-in;
	animation: fadeIn 3s !important;
	-webkit-animation: fadeIn 3s !important;
	-moz-animation: fadeIn 3s !important;
	-o-animation: fadeIn 3s !important;
	-ms-animation: fadeIn 3s !important;
}

@keyframes fadeIn {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 1;
	}
}

.feedback-form h2 {
	padding-bottom: 20px;
}
.feedback-ele {
	padding: 10px;
}

.feedback-box {
	width: 400px;
	height: 100px;
}

#main-content {
	color: white;
	position: relative;
	/* /* top: 60px;
	left: 15%;
	width: 85%; */
	/* margin: 0 auto;
    max-width: 1400px; */
	min-height: calc(100vh - 139px);
	width: 1400px;
	margin: 0 auto;
	/* left: 15%; */
	/* padding: 100px 0px; */
	padding: 20px 0px;
  
} 
.inner-sec{
	min-height: 80vh;
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
	flex-direction: column;
    /* max-width: 1400px; */
	
}
.welcome-text h1.page-heading{
letter-spacing: 0px;
color: #FFFFFF;
text-align: center;
font-size: 52px;
line-height: 58px;
/* font-family: "Barlow", sans-serif; */
font-weight: 600;
}
.inner-sec .content p{
	
		text-align: center;
	
		padding-bottom: 30px;
		letter-spacing: 0px;
		color: #fff;
		font-weight: 300;
		font-size: 18px;
		line-height: 30px;

	
}
@media (max-width:1679px){
	#main-content {
	width: 1300px;
	min-height: calc(100vh - 100px);
	}
	.welcome-text h1{
		font-size: 42px;
		line-height: 52px;
	}
	.inner-sec .content p{
		font-size: 16px;
		line-height: 26px;
	}
	.inner-sec .top-img img{
		max-width: 200px;
	}
}

@media(max-width:14407px){
	#main-content {
		width: 1200px;
		}
}

@media (max-width:1281px){
	#main-content {
	width: 900px;
	min-height: calc(100vh - 80px);
	}
	.welcome-text h1.page-heading{
		font-size: 32px;
		line-height: 42px;
	}
	.inner-sec .content p{
		font-size: 14px;
		line-height: 24px;
	}

}
@media (max-width:991px){
	#main-content {
	width: 90% !important;
	min-height: 100vh !important;
	position: unset;
	display: flex;
	justify-content: center;
	min-height: 100vh !important;
	flex-wrap: wrap;
	/* position: unset !important; */
	align-items: center;
	flex-direction: column;

	}
	.welcome-text h1.page-heading{
		font-size: 22px;
		line-height: 32px;
		display: block;
        position: unset;
	}
	h1.page-heading{
		font-size: 22px;
		line-height: 32px;
		position: fixed;
        top: 114px;
		left: 40px;
	}
	.inner-sec .top-img img{
		max-width: 170px;
	}
}


.page-heading {
	margin-bottom: 20px;
}
.upload-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	margin: 20px;
	height: 100%;
	width: 100%;
}
.upload-form label,
.upload-form input,
.upload-form select,
.upload-form button {
	padding: 5px;
	margin: 10px;
}
.upload-form h4 {
	margin: 0;
	padding: 0;
	font-style: italic;
}

#tree-name {
	width: 300px;
}
#number-of-players {
	width: 50px;
}
#stack-depth {
	width: 70px;
}
#ante,
#straddle {
	width: 60px;
}
#chip-size {
	padding: 5px;
	margin: 10px;
	width: 120px;
}
#game-type {
	padding: 5px;
	margin: 10px;
	width: 90px;
}
.info-icon {
	background: grey;
	padding: 2px 6px;
	margin: 10px;
	cursor: default;
}
.upload-container {
	background: grey;
	border-radius: 2px;
	padding: 10px 10px;
	margin: 20px;
}
.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 60px;
	border-width: 2px;
	border-radius: 2px;
	border-color: #eeeeee;
	border-style: dashed;
	background-color: #fafafa;
	color: #333;
	outline: none;
}

.dragging {
	/* border: dashed blue 4px;
	position: "absolute";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999; */
	background: rgb(162, 255, 150);
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltip-text {
	visibility: hidden;
	width: 220px;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 2px;
	border-radius: 6px;
	font-size: 0.8em;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
	left: 20px;
}
.tooltip .tooltip-text img {
	padding-top: 10px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
	visibility: visible;
}

.loading-spinner {
	margin: 30px;
}

.training-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	height: 100%;
	width: 100%;
}
.rt-setup-options {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	padding: 5px 0px;
}

.rt-setup-positions {
	display: flex;
	flex-direction: row;
	flex-basis: 0;
}
.rt-setup-options h4 {
	text-align: center;
	margin: 5px;
}
.training-form-header {
	margin: 10px;
	padding-top: 10px;
	text-align: center;
}

.training-form-select {
	padding: 2px;
}
.rt-setup-btn-container {
	padding: 0px 80px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: space-around;
}
.rt-setup-btn {
	margin: 20px;
	padding: 5px;
}
.training-form-options-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.rt-setup-group {
	min-width: 220px;
}

/* 															RANGE TRAINER */

.training-table-container {
	position: absolute;
	width: 97.5%;
	max-width: 1000px;
	top: 3%;
	/* use this to resize when multiple tables are a thing */
	transform: scale(1);
}

.table-view-pos {
	font: 1.5em sans-serif;
	pointer-events: none;
}

.table-view {
	max-width: 1000px;
	top: 2%;
}

.active {
	opacity: 1;
}

.player-circle {
	opacity: 0.8;
}

.folded {
	opacity: 0.15;
}

#dealer-button {
	opacity: 0.9;
}

.action-button {
	padding: 10px 40px;
	max-height: 50px;
}

.mix {
	background-color: rgb(156, 156, 156);
	border: none;
	border-radius: 2px;
	width: 124px;
	padding: 10px;
	outline: none;
	margin: 0px 10px 0px auto;
}
.mix:hover {
	background-color: rgb(166, 166, 166);
}

.rt-all-buttons-container {
	position: absolute;
	width: 100%;
	max-width: 1000px;
	top: 90%;
	height: 30%;
}

.rt-action-button-container {
	position: absolute;
	width: 100%;
	max-width: 1000px;
	top: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: fit-content;
	justify-content: fit-content;
	padding: 0px 100px 0px 100px;
	z-index: 100;
}
.rt-action-button-container div {
	margin-right: 70px;
	width: 100%;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.rt-answer-nav-container {
	position: absolute;
	width: 100%;
	max-width: 1000px;
	left: 0;
	bottom: 0;
	padding: 0px 150px 0px 150px;
	white-space: pre-wrap;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
}
.rt-nav-btn {
	white-space: nowrap;
	padding: 10px 20px;
}
.rt-other-btn {
	white-space: nowrap;
	padding: 10px 20px;
}

.correct {
	color: #4da208;
}
.incorrect {
	color: #b02822;
}
.inaccurate {
	color: yellow;
}

.full-width {
	width: 60%;
	margin-left: 20%;
	margin-right: 20%;
}

.hidden {
	visibility: hidden;
}
.display-none {
	display: none !important;
}

.next-button {
	margin-left: 10px;
}

.bold {
	font-weight: bolder;
}

.range-chart {
	border: 1px solid black;
	font-family: "Verdana", sans-serif !important;
	margin: 5px;
	font-size: 11px;
	background: whitesmoke;
	display: grid;
	max-width: 325px;
}
.range-chart.range-chart-large {
	font-size: 14px;
	max-width: none;
}
.freq.freq-large {
	font-size: 11px;
}

.range-row {
	display: grid;
	grid-template-columns: repeat(13, minmax(0, 1fr));
}

.range-cell {
	white-space: pre-wrap;
	cursor: default;
	color: black;
	padding: 0;
	padding-top: 2px;
	text-align: center;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none;
}

.range-chart.mini {
	max-width: 300px;
}

.range-cell.mini:hover {
	transform: scale(1.6) translate(0px, -2px);
	font-weight: bolder;
}

.freq {
	font-size: 9px;
}

.column {
	display: flex;
	flex-direction: column;
}

.range-chart-title-div {
	display: flex;
	position: relative;
	justify-content: center;
	font-size: 1.2em;
	background-color: white;
	color: black;
	font-weight: bold;
	overflow: hidden;
}

.range-chart-title {
	padding: 2px 0px;
	text-align: center;
}

.range-info-bar {
	padding: 3px;
	display: flex;
	position: relative;
	justify-content: center;
	font-size: 1em;
	background-color: white;
	color: black;
	font-weight: bold;
	overflow: hidden;
}

.pio-export-button {
	background-color: white;

	position: absolute;
	padding: 0px 2px;
	right: 0;
	border: none;
	height: 1.2em;
}
.pio-export-icon {
	background-color: white;
	border-radius: 2px;
	padding: 2px;
}

.pio-export-button :hover {
	background-color: rgb(196, 196, 196);
}

.rc-focus {
	-webkit-box-shadow: inset 0px 0px 0px 1px red;
	-moz-box-shadow: inset 0px 0px 0px 1px red;
	box-shadow: inset 0px 0px 0px 1px red;
}

.rep-message {
	padding: 0px 10px;
}

/* DRD */

/* #drd-container {
	border-top: 4px solid rgb(226, 226, 226);
	border-left: 4px solid rgb(196, 196, 196);
	border-right: 4px solid rgb(166, 166, 166);
	border-bottom: 4px solid rgb(146, 146, 146);
	display: grid;
	align-content: center;
	width: 420px;
	grid-template-columns: 1fr;
	grid-template-rows: 30px 3fr auto 50px;
	grid-auto-flow: column;
	gap: 10px;
	padding: 10px;
	margin: 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
} 
*/

#drd-container {
	border-top: 4px solid rgb(226, 226, 226);
	border-left: 4px solid rgb(196, 196, 196);
	border-right: 4px solid rgb(166, 166, 166);
	border-bottom: 4px solid rgb(146, 146, 146);
	display: grid;
	align-content: center;
	width: 420px;
	max-height: 800px;
	grid-template-columns: 1fr;
	/* grid-auto-rows: min-content; */
	/* grid-template-rows: 5fr 25fr 5fr 1fr 5fr; */
	/* grid-auto-flow: column; */
	/* gap: 10px; */
	padding: 10px;
	margin: 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pos-btn-container {
	display: grid;
	min-height: 240px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* 6-max */
.pos-btn-container.six-max {
	grid-template-rows: 100px 100px;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-flow: row dense;
	gap: 20px;
	padding: 20px 10px;
}
/* Found online to make the position buttons grid in a 'snake case'/circle - will probably need to find a different solution for 4/5/7/8/9 handed. true in DRT as well */
.pos-btn-container.six-max > button:nth-child(4n + 4) {
	grid-column: 3;
}
.pos-btn-container.six-max > button:nth-child(4n + 5) {
	grid-column: 2;
}

/* Full-ring */
.pos-btn-container.full-ring {
	grid-template-rows: 100px 100px;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-flow: row dense;
	gap: 15px;
	padding: 20px 10px;
}
.pos-btn-container.full-ring > button:nth-child(5) {
	grid-column: 4;
}
.pos-btn-container.full-ring > button:nth-child(6) {
	grid-column: 3;
}
.pos-btn-container.full-ring > button:nth-child(7) {
	grid-column: 2;
}

.drd-pos-btn {
	border: none;
	border-radius: 15%;
	padding: 20px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	background-color: rgb(239, 239, 239);
	color: rgb(0, 0, 0);
}

.drd-pos-btn:disabled {
	background-color: rgb(239, 239, 239, 0.3);
	color: rgb(19, 1, 1, 0.3);
}

.drd-clear-btn-container {
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: space-around;
}

.drd-clear-btn {
	margin: 10px 0px;
	justify-content: center;
	padding: 8px 12px;
}

.drd-description {
	font-size: 0.9em;
	display: flex;
	text-align: center;
	margin: auto;
}

.drd-tree-select {
	display: flex;
	justify-self: center;
	align-self: center;
	max-width: 350px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 2px;
}

.drd-tree-info-label {
	display: flex;
	justify-content: center;
	font-size: 0.7em;
}

.drd-tree-info-pane {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 0.85em;
	background-color: #555;
	margin: 0px 35px;
	padding: 10px;
}

.pressed {
	border: none;
	box-shadow: inset 0 0 10px #000000;
	filter: brightness(85%);
}

.rd-container {
	display: inline-block;
	position: fixed;
	border: 1px solid black;
	background: lightgrey;
	color: black;
	text-align: center;
	padding: 1px;
}

.range-display {
	position: relative;
}

.range-display-tree-label {
	position: absolute;
	top: 6%;
	left: 3px;
	font-size: 0.65em;
	color: rgb(140, 140, 140);
	max-width: 37.5%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.range-display-title {
	padding: 5px 15px 0 15px;
	font-weight: bold;
}
.range-display-subtitle {
	/* padding: 5px 0 0 0; */
	font-weight: bold;
	font-size: 0.75em;
}

.drd-pos-btn.call {
	background: #238e51;
}
.drd-pos-btn.squeeze {
	background: #9149b3;
}
.drd-pos-btn.open {
	background: #1156c1;
}
.drd-pos-btn._3-bet {
	background: #f7d708;
}
.drd-pos-btn._4-bet {
	background: #ff9e00;
}
.drd-pos-btn._5-bet {
	background: #d94d11;
}
.drd-pos-btn.all-in {
	background: #ce0000;
}
/* 
.select-tree-label {
	float: left;
	justify-self: center;
	margin-right: 5px;
} */

.rd-exit-button {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-5%, 5%);
	border: none;
	padding: 1px 3px 1px 3px;
	background-color: lightgrey;
	font-size: 1.1em;
	z-index: 1000;
}

.rd-exit-button:hover {
	background-color: rgb(156, 156, 156);
}

.no-range-available {
	font-style: italic;
}

/* ------------------------------------------------------- MUI MULTI TREE SELECTOR */
.MuiAutocomplete-root {
	background-color: white;
	border-radius: 2px;
	width: 370px;
	margin: auto;
	/* display: flex;
	justify-content: center;
	align-items: center; */
}

@media(max-width:579px){
	.MuiAutocomplete-root{
	width:240px;
	}
	}

.MuiChip-label {
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* -------------------------------------------------------  DATA */

.data-back-btn {
	display: flex;
	justify-self: center;
	align-self: center;
	padding: 5px 10px;
	margin: 10px 0px;
}

.data-stat {
	font-weight: bold;
	font-size: 15;
	text-shadow: 1px 1px #222;
}

.data-clicked {
	background-color: rgba(0, 0, 0, 0.7);
}

.data-scenario-container {
	width: 1800px;
	position: relative;
	white-space: nowrap;
}

.data-detail-container {
	position: absolute;
	top: 0;
	left: 950px;
	width: 400px;
	min-height: 400px;
	max-height: 800px;
	margin: 40px;
	text-align: center;
}

.data-detail-rng-cnt {
	min-height: 400px;
}

.data-detail-header {
	padding: 6px;
	font-weight: bold;
}

.player-spot-range-chart {
	border: 1px solid black;
	font-family: "Verdana", sans-serif !important;
	margin: 5px;
	font-size: 11px;
	background: whitesmoke;
	max-width: 325px;
	position: relative;
	display: inline-block;
}

.mistake-list {
	max-height: 400px;
	overflow: auto;
	background-color: #aaa;
}

.rep-summary {
	display: flex;
	max-height: 100px;
	width: 80%;
	margin: 10px 0px 10px 40px;
	padding: 5px;
	cursor: default;
}

.mini-card {
	min-height: 40px;
	max-height: 40px;
	width: 30px;
	border: 2px solid white;
	border-radius: 2px;
	color: white;
	vertical-align: top;
	text-align: left;
	padding: 2px;
	font-size: 0.9em;
	font-weight: bold;
}

.rep-summary-labels {
	text-align: left;
}

.rep-summary-timestamp {
	font-size: 0.65em;
	color: darkgrey;
}

.rep-summary-score {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: 2px;
	background-color: #555;
	width: 50px;
	padding: 10px 0px;
}

.rep-summary-action {
	font-size: 0.8em;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
	outline: none !important;
}

.MuiDataGrid-row:nth-of-type(odd) {
	background-color: rgba(120, 255, 95, 0.3);
}
.MuiDataGrid-row:nth-of-type(even) {
	background-color: rgba(120, 255, 95, 0.5);
}

.breakdownBottomMenu {
	display: flex;
	flex-direction: row;
}
.rep-range-container {
	display: flex;
	align-items: center;
}
.rep-range-label {
	margin: 0;
	padding: 0px 10px 0px 30px;
	width: 275px;
	font-style: italic;
	font-size: 0.9em;
}
.rep-checkbox-container {
	display: flex;
	flex-direction: row;
	padding: 0px 10px 0px 20px;
}
.rep-checkbox {
	display: flex;
	flex-direction: row;
	padding: 0px 5px;
}

#repRange {
	direction: rtl;
}

.totals-row {
	border-top: 2px solid black;
}

/* ------------------------------------------------------------------- SETTINGS ------- */
.settings-tooltip {
	font-size: 1.5em;
	padding: 2px;
}
.settings-tooltip-icon {
	vertical-align: middle;
}
.settings-heading {
	padding-top: 10px;
}

/* -------------------------------------------------------------------- MOBILE ------- */
@media (max-width: 800px) {
	.page-heading {
		position: fixed;
		top: 6px;
		left: 25vw;
		font-size: 1.1em;
		/* z-index: 10000; */
	}
	#main-content {
		left: 0%;
		width: 100%;
	}

	/* 													--- SIDEBAR --- */
	/* Display hamburger menu instead of individual buttons*/
	.sidebar .sidebar-icon {
		background-color: rgb(239, 239, 239);
		color: rgb(0, 0, 0);
		display: block;
		height: 40px;
		width: 20vw;
	}
	.sidebar-button {
		display: none;
	}
	/* Displays buttons when menu icon is clicked*/
	.sidebar.show .sidebar-button {
		display: block;
		width: 98vw;
		padding: 20px 0px;
	}
	/* Sidebar mobile styling */
	.sidebar {
		height: 45px;
		position: fixed;
		z-index: 5000;
		width: 20%;
		top: 5px;
		left: 5px;
		background: none;
		text-rendering: optimizeLegibility;
		display: grid;
		align-items: center;
	}

	/*                                                 --- NAVBAR --- */
	/* Hides nav bar items when not on the home page */
	.global-nav.mobile-hide .nav-item {
		visibility: hidden;
	}
	/* Hides nav bar items not supported on mobile */
	.mobile-hide.nav-item {
		visibility: hidden;
	}

	.global-nav {
		/* height: 50px; */
		position: fixed;
		z-index: 5000;
		top: 0;
		width: 100vw;
		background-color: #0e1217;
		text-rendering: optimizeLegibility;
		display: flex;
		flex-direction: row-reverse;
		justify-content: right;
		align-items: center;
	}
	.nav-item {
		/* color: rgb(239, 239, 239);
		text-align: center;
		text-decoration: none;
		font-size: 0.75em;
		padding: 2vw;
		border-left: 1px solid #3f444d; */
	}
	#upload-link {
		display: none;
	}

	.nav-heading {
		display: flex;
		align-items: center;
		margin: 1vw 2vw 2vw 0vw;
		color: rgb(239, 239, 239);
	}
	.nav-heading:before {
		display: inline-block;
		height: 30px;
		border-left: 1px solid #3f444d;
		position: relative;
		content: "";
		vertical-align: middle;
	}
	#main-content {
		min-height: 0;
		padding: 0;
	}
	/*                                                     --- DRD --- */
	#drd-container {
		border: none;
		width: 95vw;
		padding-left: 1vw;
		padding-right: 2vw;
	}
	.drd-description {
		font-size: 0.8em;
		display: flex;
		text-align: center;
		margin: auto;
	}
	.range-chart {
		width: 95vw;
		max-width: none;
	}
	.drd-clear-btn-container {
		position: fixed;
		bottom: 0px;
		left: 5%;
		width: 90%;
	}
	.drd-clear-btn {
		background-color: rgb(239, 239, 239);
		color: rgb(0, 0, 0);
		margin: 10px 0px;
		justify-content: center;
		padding: 16px 18px;
	}
	.range-display-tree-label {
		top: 28px;
	}
	/*                                                     --- DRT Setup--- */

	.training-form-options-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
	}

	.rt-setup-btn-container {
		position: fixed;
		bottom: 0;
		left: 2.5%;
		width: 95%;

		justify-content: space-between;
		z-index: 0;
	}
	.rt-setup-btn {
		margin: 10px 0px;
		padding: 12px 16px;
		justify-content: center;
		z-index: 10000;
	}

	.rt-setup-options {
		margin: 5px 5px;
		background: rgb(78, 78, 78);
		flex-grow: 1;
		flex-basis: 0;
	}

	.training-form {
		margin: auto;
		padding: 90px 10px;
		padding-top: 0px;
	}
	.training-form-header {
		margin: 0px;
		margin-top: 10px;
	}
	/*                                                     --- MOBILE: RANGE TRAINER --- */

	.table-view {
		position: fixed;
		width: 100vw;
		top: 10vh;
	}

	.rt-all-buttons-container {
		position: fixed;
		height: auto;
		top: 100vh;
		transform: translateY(-125%);
		display: flex;
		flex-direction: column-reverse;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}
	.rt-action-button-container {
		position: relative;
		padding: 20px;
		margin: 0;
		width: 100vw;
		z-index: 1;
		display: flex;
		flex-direction: column-reverse;
	}
	.rt-action-button-container div {
		display: flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	.rt-action-button {
		margin: 0px 10px;
	}
	.rt-answer-nav-container {
		position: relative;
		top: 0;
		bottom: initial;
		width: 100vw;
		max-width: 800px;
		padding: 0px;
		z-index: 1;
	}
	#rng {
		width: fit-content;
		margin: 0px 0px 10px auto;
	}
	.action-button {
		font-size: 0.8em;
		white-space: normal;
		padding: 15px 30px;
	}

	.rt-nav-container {
		display: flex;
		flex-direction: row;
		margin-left: 5px;
	}
	.rt-nav-btn {
		font-size: 0.7em;
		padding: 8px;
		margin: 0px 2px;
	}
	.rt-other-btn {
		font-size: 0.7em;
		padding: 8px 2px;
		margin: 5px;
	}
	.rep-message {
		font-size: 0.9em;
	}
	.table-view-container {
		position: fixed;
		width: 100%;
		top: 20%;
	}
	#feedback-panel {
		padding: 5px;
	}
	.homepage-header {
		display: none;
	}
}

.drd-err-msg {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-style: italic;
	font-weight: normal;
	margin: auto;
}

#player-spot-chart-plo-error {
	font-style: italic;
	background-color: #aaa;
}

.settings-select {
	margin-left: 20px;
}

.rt-hotkey-text {
	font-size: 1.4em;
}

.arrow-drop-container {
	cursor: pointer;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.multi-tree-select {
	padding: 10px 0px 0px 0px;
}

.hand-marked-snackbar {
	background-color: #2e7d32 !important;
}
.data-selector-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.data-selector-container .multi-tree-select {
	float: left;
	padding: 0px;
}

.tool-button {
	width: 100px;
	padding: 10px;
	margin: 10px;
}

.tool-button a {
	text-decoration: none;
	color: #000000;
}

.tool-label {
	font-size: 1.2em;
	font-weight: bold;
}

.pio-mass-export-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.homepage-header {
	animation: fadein 1.75s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.tree-man-view-ctn {
	width: 100%;
	padding: 10px;
}

.tree-man-view-btn {
	margin: 30px 40px 30px 0px;
	padding: 10px;
	border: 1px solid rgb(60, 60, 60);
	border-radius: 4px;
	background: #222;
	cursor: pointer;
}
.tree-man-view-btn:hover {
	background: #333;
}

.tree-man-view-btn.active {
	background: #333;
	text-decoration: underline;
}

.tree-rake-faq-cnt {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 20px;
	background-color: #333;
	max-width: 1200px;
}

.rake-faq-list {
	margin-top: 20px;
	margin-left: 50px;
}
.rake-faq-list-head {
	margin-top: 20px;
}

.rake-faq-sublist {
	margin-left: 50px;
}

/* PLO RANGE DISPLAY  */

.plo-range-stack {
	border: 1px solid black;
	margin: 5px;
}

.plo-tree-label {
	position: absolute;
	top: 0;
	margin-top: 25px;
	left: 3px;
	font-size: 0.65em;
	color: rgb(140, 140, 140);
	max-width: 32.5%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.plo-header-container {
	display: flex;
	flex-direction: row;
	background-color: whitesmoke;
	padding: 2px;
}
.plo-header {
	width: 100px;
	margin-right: auto;
}
.plo-subheader {
	display: flex;
	flex-direction: row;
}
.plo-subheader h5 {
	padding: 2px 6px;
	margin: auto;
	transition: 0.2s all ease;
	border: 1px solid transparent;
}
.plo-subheader h5:hover {
	border: 1px solid rgb(175, 175, 175);
}
.plo-tile-container {
	min-height: 550px;
}

.plo-range-tile {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	color: black;
}

.plo-tile-item {
	width: 50px;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
}

.hand-col {
	width: 100px;
}
div.hand-col {
	text-align: start;
}

.plo-tile-cell {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.plo-range-tile:nth-of-type(even) {
	background-color: #ececec;
}
.plo-range-tile:nth-of-type(odd) {
	background-color: #e2e2e2;
}

.plo-be-indicator {
	overflow: hidden;
	text-align: center;
	color: transparent;
	padding: 1px 0px 2px 0px;
}
/* .plo-fold-indicator:before, */
.plo-be-indicator:after {
	background-color: rgb(255, 0, 0);
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	padding: 0px 5px;
}
.plo-be-indicator:before {
	right: 0.5em;
}
.plo-be-indicator:after {
	left: 0.5em;
}

.plo-header.small {
	font-size: 0.75em;
}
.hand-col-foot {
	font-size: 0.75em;
	width: 100px;
}

.plo-sort-focus::after {
	content: " v";
	font-size: 0.7em;
}

.plo-hand-container {
	display: flex;
	flex-direction: row;
	margin-left: 5px;
	cursor: pointer;
}

.plo-card {
	border-radius: 5%;
	border-bottom: 1px solid white;
	color: white;
	padding: 1px;
}
.plo-card.red {
	background-color: var(--card-red);
}
.plo-card.black {
	background-color: var(--card-black);
}
.plo-card.blue {
	background-color: var(--card-blue);
}
.plo-card.green {
	background-color: var(--card-green);
}

.plo-stack-container {
	display: flex;
	flex-direction: row;
}
/* PLO DRD filter buttons */
.plo-filters-container {
	padding: 5px;
	border: 1px solid black;
	margin-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: auto;
	background-color: #cacaca;
}
.plo-filter-header {
	padding: 5px;
}
h4.plo-filter-header {
	border: 1px solid #333;
	background-color: whitesmoke;
	margin-bottom: 5px;
}
.plo-filter-btn {
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 80px;
	margin: 7px 5px;
	padding: 2px 4px;
}
.plo-filter-btn .plo-card {
	color: transparent;
	border: 1px solid white;
}

/* PLO DRD Search Field */

.plo-search-container {
	display: flex;
	flex-direction: row;
	padding: 5px;
}

.plo-search-field {
	border: 1px solid rgb(172, 172, 172);
	border-bottom: 1px solid black;
	height: 25px;
	width: 80px;
	transition: 0.2s all ease;
}
.plo-search-field:hover {
	border: 1px solid #333;
}

.plo-search-field:active,
.plo-search-field:focus {
	border: 2px solid rgb(17, 17, 17);
	outline: none;
}

.plo-x-button {
	text-align: center;
	width: 20px;
	height: 25px;
	padding: 0px 3px 1px 3px;
	margin-left: 5px;
	border: none;
	border-radius: 4px;
	color: rgb(63, 63, 63);
	transition: 0.2s all ease;
}

.plo-x-button:hover {
	background-color: rgb(205, 205, 205);
}

.plo-scroll-bar {
	margin: 50px 5px 18px 0px;
	width: 5px;
	background-color: darkgrey;
	border-radius: 4px;
}
.plo-scroll-handle {
	background-color: rgb(99, 99, 99);
	border-radius: 2px;
	min-height: 4px;
	margin-bottom: 0px;
}

.plo-filter-btn.clicked {
	box-shadow: inset 0 0 4px #000000;
	filter: brightness(85%);
}

.training-range .plo-card {
	font-size: 1.5rem;
}

/* ACCOUNTING */

.site-input-cnt {
	background-color: #4d4d4d;
	padding: 20px;
	margin: 0px 20px 10px 20px;
	max-width: fit-content;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
	text-shadow: 1px 1px black;
}

.site-input-header {
	padding-bottom: 10px;
	border-bottom: 1px solid rgb(101, 101, 126);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.site-delete {
	cursor: pointer;
	border-radius: 50%;
	border: 0px;
	padding: 4px 8px;
	background-color: #333333;
	display: flex; /* or inline-flex */
	align-items: center;
	justify-content: center;
}
.site-delete:hover {
	filter: brightness(120%);
}

.site-input-row {
	padding: 10px 5px;
	display: flex;
	justify-content: space-between;
}

.site-input-bottom {
	padding-top: 5px;
	border-bottom: 1px solid rgb(101, 101, 126);
	font-weight: bold;
}

.site-input-cnt div label {
	width: 175px;
	margin-right: 20px;
	margin-top: auto;
	margin-bottom: auto;
	font-weight: bold;
}

.site-input-cnt div input {
	width: 100px;
	margin: 0px;
	text-align: right;
}

.site-input-cnt div input:read-only {
	background: #333;
	color: white;
}

.site-input-cnt div input::before {
	content: "$";
}
.site-input-cnt div input::after {
	content: "CAD";
}

.site-input-cnt div button {
	font-size: 0.75em;
	margin: 0px 0px 0px 20px;
	padding: 0px 5px;
	color: white;
	border: 1px solid white;
	border-radius: 2px;
	background-color: #1b1b1b;
}

.site-input-cnt div button:hover {
	background-color: #333333;
}

.monthly-result-input-ctn {
	padding: 40px 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
}

.accounting-ctn {
	padding: 40px;
}

.accounting-view-btn {
	margin: 0px 40px 30px 0px;
	padding: 10px;
	border: 1px solid rgb(60, 60, 60);
	border-radius: 4px;
	background: #222;
	cursor: pointer;
}
.accounting-view-btn:hover {
	background: #333;
}

.accounting-view-btn.active {
	background: #333;
	text-decoration: underline;
}

.screenshot-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.add-screenshot-cnt {
	background-color: #4d4d4d;
	border-radius: 4px;
	box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
	padding: 20px;
	position: absolute;
	width: 400px;
	top: 40%;
	left: 0;
	right: 0;
	margin: auto;
}
.add-screenshot-cnt h4 {
	text-align: center;
	margin: auto;
	padding: 10px 5px;
}

.add-screenshot-cnt form input {
	margin: 2%;
	width: 70%;
}

.add-screenshot-cnt form button {
	font-size: 1em;
	max-width: 12%;
	margin: 0px;
	padding: 2px 4px;
	color: white;
	border: 1px solid white;
	border-radius: 2px;
	background-color: #1b1b1b;
}

.acc-calendar-cnt {
	margin-right: 10%;
}

.acc-calendar {
	display: flex;
	flex-wrap: wrap;
}

.acc-calendar-header {
	width: 100%;
	margin: 20px auto;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
}
.acc-calendar-header h2 {
	padding: 10px 20px;
}
.acc-calendar-header span {
	cursor: pointer;
	font-size: 1.8em;
}
.acc-calendar-header span:hover {
	filter: brightness(60%);
}

.acc-calendar-month {
	flex: 1 0 19%;
	margin: 3%;
	padding: 5px 10px;
	display: flex;
	flex-direction: column;
	border: 1px solid grey;
	border-radius: 5px;
	background-color: #0e1217;
}

.acc-calendar-month h4 {
	margin: 10px auto;
}

.acc-calendar-month button {
	margin: 0px 30px 10px 30px;
}

.month-input-header {
	margin: 30px 0px 0px 0px;
}

.screenshot-list-cnt {
	padding: 0;
	padding-top: 10px;
	margin: 0;
	display: flex;
	flex-direction: column;
}

.link-cnt {
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
}
.note-cnt {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-basis: 0;
	max-width: 460px;
	width: 100%;
}
.note-label {
	text-decoration: underline;
	padding: 0px 5px;
	min-width: fit-content;
}
.note-content {
	display: flex;
	flex: 1;
	flex-grow: 1;
	padding: 2px;
	font-style: italic;
}
.note-delete {
	cursor: pointer;
	border-radius: 50%;
	border: 0px;
	padding: 4px 8px;
	background-color: #333333;
	width: 25px;
	margin: 0px 0px 0px auto;
}
.note-add-cnt {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}
.note-add-button {
	padding: 3px 10px !important;
}
.note-add-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}
.note-add-textarea {
	width: 300px;
	height: 100px;
}

.screenshot-link {
	cursor: pointer;
	text-decoration: underline;
	padding: 5px;
}

.screenshot-delete {
	cursor: pointer;
	border-radius: 50%;
	border: 0px;
	padding: 4px 8px;
	background-color: #333333;
}
.screenshot-delete:hover,
.note-delete:hover {
	filter: brightness(120%);
}

.add-site-cnt {
	background-color: #4d4d4d;
	border-radius: 4px;
	box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
	padding: 20px;
	position: absolute;
	width: 400px;
	top: 40%;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.add-site-cnt h4 {
	text-align: center;
	margin: auto;
	padding: 10px 5px;
}

.add-site-cnt form input {
	margin: 2%;
	width: 70%;
}

.add-site-cnt form {
	width: 350px;
	text-align: center;
}

.add-site-cnt form button {
	display: inline-block;
	font-size: 1em;
	margin: 10px 5px 5px 5px;
	width: 125px;
	padding: 2px 4px;
	color: white;
	border: 1px solid white;
	border-radius: 2px;
	background-color: #1b1b1b;
}

.acc-add-site-button {
	display: block;
	margin: auto;
	border-radius: 50%;
	border: 0px;
	padding: 10px 18px;
	font-size: 1.5em;
	background-color: #4d4d4d;
	color: white;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.acc-add-site-button:hover {
	background-color: rgb(85, 85, 85);
}
.finalize-results {
	display: block;
	height: 40px;
	margin: auto 0px;
	font-size: 1.25em;
	padding: 5px;
	color: white;
	border: 1px solid white;
	border-radius: 2px;
	background-color: #4d8836;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.finalize-results:hover {
	filter: brightness(110%);
}

.acc-month-extra-buttons {
	padding: 20px;
	height: 240px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.site-summary-cnt {
	background-color: #4d4d4d;
	width: 400px;
	padding: 20px;
	margin: 10px;
	border: 1px solid white;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-shadow: inset 3px 3px rgba(0, 0, 0, 0.2);
	filter: brightness(105%);
	text-shadow: 1px 1px rgb(31, 31, 31);
}

.site-summary-header {
	padding-bottom: 10px;
	border-bottom: 1px solid rgb(101, 101, 126);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-overflow: ellipsis;
}

.site-summary-row {
	padding: 10px 5px;
	display: flex;
	justify-content: space-between;
}

.site-summary-bottom {
	padding-top: 10px;
	border-bottom: 1px solid rgb(101, 101, 126);
	font-weight: bold;
}

.profit-green {
	color: #28b364;
}
.profit-yellow {
	color: rgb(228, 228, 81);
}
.profit-red {
	color: rgb(248, 74, 74);
}

.month-result-total-ctn {
	background-color: #4d4d4d;
	border: 1px solid white;
	border-radius: 4px;
	padding: 10px;
	margin: 0px 5% 20px 20px;
	min-width: 440px;
	max-width: 1100px;
	width: 50%;
	display: flex;
	align-items: center;
	box-shadow: 3px 3px rgb(31, 31, 31);
	text-shadow: 2px 1px rgb(31, 31, 31);
}
.month-result-total-start {
	font-size: 1.1em;
	font-weight: bold;
	margin: 0px 0px 0px auto;
}
.month-result-total-mid {
	font-size: 1.1em;
	font-weight: bold;
	margin: 0px 12px;
}
.month-result-total-end {
	font-size: 1.1em;
	font-weight: bold;
	margin: 0px auto 0px 0px;
	background-color: #4d4d4d;
	color: white;
	text-shadow: 2px 1px rgb(31, 31, 31);
}
.month-result-total-end:focus {
	outline: none;
}

.acc-user-select-cnt {
	background: grey;
	border-radius: 2px;
	padding: 20px;
	position: absolute;
	right: 75px;
	top: 25px;
	box-shadow: 2px 2px black;
}

.exchange-rate-ctn {
	background-color: #4d4d4d;
	border: 1px solid white;
	border-radius: 4px;
	padding: 10px;
	margin: 0px 20px 20px 20px;
	min-width: 150px;
	width: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 3px 3px rgb(31, 31, 31);
	text-shadow: 2px 1px rgb(31, 31, 31);
}

.month-summary-cnt {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.monthly-result-sites-ctn {
	padding: 40px 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
}

.monthly-result-summary-ctn {
	max-width: 90%;
}

.acc-deal-creator h2 {
	margin: 50px 0px 25px 0px;
}

.adc-dealtype-cnt {
	display: flex;
	flex-direction: row;
	margin: 25px 25px 25px 0px;
	width: 100%;
}
.react-datepicker-wrapper {
	width: 100px !important;
}

.adc-dealtype-cnt h4 {
	margin: 0px 10px 0px 25px;
}

.adc-subject-cnt {
	display: flex;
	flex-direction: row;
}

.adc-action-holders-cnt h3 {
	margin-bottom: 25px;
}

.adc-add-shareholder-cnt {
	display: flex;
	flex-direction: row;
	margin-left: 25px;
}

.adc-add-shareholder-cnt h4 {
	margin-right: 10px;
}

.adc-shareholder-cnt {
	margin: 25px;
	background-color: #444;
	max-width: 800px;
	padding: 10px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.adc-sh-name {
	min-width: 125px;
}

.adc-shareholder-input {
	max-width: 3em;
}

.adc-percentage {
	margin: 10px;
}

.adc-sh-remove {
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 50%;
}
.adc-sh-remove:hover {
	background-color: #555;
}
.adc-submit-button {
	margin: 25px 75px;
	padding: 5px;
}

.deals-overview-cnt {
	margin-top: 25px;
}

.deals-overview-cnt h2 {
	margin-top: 50px;
}

.deals-overview-cnt h3 {
	margin-top: 15px;
}

.deal-detail-cnt {
	padding: 5px;
	border-radius: 5px;
	background-color: #444;
	width: 600px;
	margin: 15px;
}

.deal-detail-cnt h3 {
	margin: 15px 10px;
}

.deal-detail-row {
	display: flex;
	justify-content: space-between;
	margin-left: 15px;
}
.deal-detail-row span {
	width: 22.5%;
	padding: 5px 5px;
}

.deal-detail-row span span {
	font-weight: bold;
}
.deal-detail-button-cnt {
	display: flex;
	justify-content: space-around;
}
.deal-detail-button-cnt button {
	padding: 2px;
	margin: 15px;
}
.deal-detail-button-cnt span {
	padding: 15px 25px;
	margin: auto 15px;
	font-style: italic;
	color: rgb(174, 174, 174);
}

.site-input-deal-label {
	margin-top: auto;
	margin-bottom: 5px;
	font-style: italic;
	filter: brightness(80%);
	font-size: small;
}

.site-summary-deal-label {
	margin-top: auto;
	font-style: italic;
	filter: brightness(70%);
	font-size: small;
}

.deal-share-summary-cnt {
	background-color: #4d4d4d;
	border: 1px solid white;
	border-radius: 4px;
	padding: 10px;
	margin: 0px 5% 20px 20px;
	min-width: 440px;
	max-width: 1100px;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 3px 3px rgb(31, 31, 31);
	text-shadow: 2px 1px rgb(31, 31, 31);
}
.deal-share-summary-cnt h3 {
	margin-bottom: 10px;
}

/* .deal-share-profit-column {
	width: 600px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	text-align: flex-start;
}

.deal-share-item {
	width: 50%;
	align-items: center;
} */

.deal-share-amount-owed-cnt {
	width: 400px;
	padding: 10px;
}

/* .month-result-total-ctn {
} */

.student-results-cnt {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/* align-items: flex-start; */
}

.student-result {
	background-color: #4d4d4d;
	width: 30%;
	padding: 20px;
	margin: 10px;
	border: 1px solid white;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-shadow: inset 3px 3px rgba(0, 0, 0, 0.2);
	filter: brightness(105%);
	text-shadow: 1px 1px rgb(31, 31, 31);
}

.student-result-header {
	margin-bottom: 5px;
	border-bottom: 1px solid rgb(101, 101, 126);
}

.student-result-row {
	padding: 5px;
}

.student-msg {
	font-style: italic;
	line-height: 120px;
	filter: brightness(90%);
}

.unfinalize-result-btn {
	width: fit-content;
	margin: 10px auto 0px auto;
	padding: 2px 4px;
	background-color: rgb(246, 246, 246);
	border-radius: 3px;
	border: 1px solid rgb(102, 102, 102);
}

.add-settlement-header {
	border-top: 1px solid rgb(255, 255, 255);
	margin-top: 25px !important;
}

.settlement-amount-input {
	padding: 20px 5px;
	border-bottom: 1px solid rgb(101, 101, 126);
}

.tree-man-filter-ctn {
	display: flex;
	flex-direction: row;
	width: 80%;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 20px;
	position: relative;
}

.tree-man-filter-ctn div {
	width: 25%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.tree-man-filter-ctn div select {
	margin-left: 4px;
	min-width: 50px;
}

.tree-man-clear-btn {
	position: absolute;
	right: -100px;
	top: 50%;
	transform: translateY(-50%);
	padding: 10px;
	border: 2px solid white;
	color: white;
	border-radius: 4px;
	background: #222;
	cursor: pointer;
	font-weight: bold;
}
.tree-man-clear-btn:hover {
	background: #333;
}

.subscribe-cnt {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.subscribe-cnt h1 {
	padding-top: 2%;
	padding-bottom: 3%;
}

.subscribe-card-cnt {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 80%;
	max-width: 800px;
	margin: 0px auto 40px auto;
}
.subscribe-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.08);
	padding: 20px;
	min-width: 360px;
	min-height: 240px;
	height: 100%;
}
.subscribe-card-label {
	font-size: 1.8rem;
}
.subscribe-freq {
	filter: brightness(0.6);
}

.subscribe-btn {
	padding: 10px 25px;
	font-size: 1.1rem;
	font-weight: bold;
	background-color: maroon;
	color: white;
	border: none;
	border-radius: 5px;
	margin: 0 10%;
}
.subscribe-btn:hover {
	filter: brightness(1.2);
	cursor: pointer;
}

.subscribe-feature-list {
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 5px;
	margin: 0px auto;
	width: 100%;
	width: 800px;
	padding: 30px 40px;
	font-size: 1.1rem;
}


.p-Input-input {
	background-color: red !important;
} 
	/* border:1px solid $signupFormBorder; */
	/* background-color: red; */
	/* border-radius: 29px;
	color: $white;
	&::placeholder{
		color: $white;
		opacity: 1;
	}
	&::-moz-placeholder{
		color: $white;
		opacity: 1;
	}
	&::-webkit-placeholder{
		color: $white;
		opacity: 1;
	}
} */
