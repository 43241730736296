@import "partials/partials";

 
.modal-subscribe-success{
    .plan-head{
        padding: 20px;
        background-color: var(--tableHeaderBg);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 24px;
        position: relative;
        @media ($sm-1679){
            padding: 16px;
            border-radius: 20px;
        }
        @media ($sm-1441){
            border-radius: 18px;
        }
         
        .plan-badge{
            position: absolute;
            bottom: 0;
            transform: translateY(50%);
            background-color: var(--colorPrimary);
            color: #ffffff;
            padding: 5px 12px 5px 5px;
            border-radius: 20px;
            font-weight: 400;
            font-size: 13px;
            display: flex;
            align-items: center;
            line-height: 1;
            .txt{
                margin-left: 5px;
            }
            .ico{
                img{
                    max-width: 12px;
                    object-fit: contain;
                }
            }
        }
        .plan-titles{
            // display: flex;
            align-items: center;
            justify-content: space-between;
            .ico{
                max-width: 60px;
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }
        } 
        .service{
            font-size: 14px;
            color: #ffffff;
            font-weight: 600;
        }
        .rate{
            font-size: 34px;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            line-height: 1;
            @media ($sm-1679){
                font-size: 30px;
            }
            @media ($sm-1441){
                font-size: 24px;  
            }
            @media ($sm-1199){
                font-size: 20px;   
            }
            .billcycle{
                font-size: 0.53em;
                font-weight: 600;
                color: var(--colorPrimary);
            }
        }
    } 
    .modal-content{
        background-image: url(../images/successmodalBg.svg);
        background-repeat: no-repeat;
        background-position: top left;
    }
    .success-ico{ 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 180px;
            @media ($sm-1679){
                max-width: 160px;
            }
            @media ($sm-1441){
                max-width: 140px;  
            }
            @media ($sm-1199){
                 
            }
        }
    }
    .modal-title,
    .modal-desc{
        text-align: center;
    }

    .modal-title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
    }
    .modal-footer{
        padding-top: 40px !important;
        margin-top: 30px;
        border-top: 1px dashed #707070 !important;
        @media ($sm-1679){
            padding-top: 30px !important;
            margin-top: 20px;
        }
        @media ($sm-1441){
            padding-top: 20px !important;
            margin-top: 14px;  
        }
         
    }
    .footer-table{
        width: 100%;
        font-feature-settings: 'lnum' 1;
        td{
            border: none;
            color: var(--txtLight);
        }
        .val{
            font-weight: 700;
            color: #ffffff;
        }
    }
}

.modal-forgot{ 
    .modal-content{
        background-image: url(../images/successmodalBg.svg);
        background-repeat: no-repeat;
        background-position: top left;
    }
    .success-ico{ 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
            max-width: 180px;
            @media ($sm-1679){
                max-width: 160px;
            }
            @media ($sm-1441){
                max-width: 140px;  
            }
            @media ($sm-1199){ 
                 max-width: 100px;
            }
        }
    }
    .modal-title,
    .modal-desc{
        text-align: center;
    }

    .modal-title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
    }
}

.login-tile{
    color: #ffffff;
    margin-bottom: 48px;
    font-size: 24px;
    @media ($sm-1679){
        margin-bottom: 36px;
        font-size: 20px;
    }
    @media ($sm-1441){
        margin-bottom: 20px;
        font-size: 18px;
    }
     
}


.modal-subscribe-success.modal-subscribe-success1{
    .modal-body{
        padding: 43px 52px 35px !important;
        @media ($sm-1679){
            padding: 36px 48px 10px !important;
        }
        @media ($sm-1441){
            padding: 30px 42px 10px !important;
        }
        @media ($sm-1199){
            padding: 20px 30px 10px !important;   
        }
        @media ($sm-767){
            padding: 20px 20px 10px !important;   
        }
    }
    .modal-footer{
        padding: 34px 52px 40px !important;
        border-top: none !important;
        @media ($sm-1679){
            padding: 36px 48px 10px !important;
        }
        @media ($sm-1441){
            padding: 30px 42px 10px !important;
        }
        @media ($sm-1199){
            padding: 20px 30px 10px !important;   
        }
        @media ($sm-767){
            padding: 20px 20px 10px !important;   
        }
        border-top: none;
        .btn{
            border: none;
          
        }
    }
    .plan-head{
        background: transparent linear-gradient(180deg, #121212 0%, #000000 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #1D1D1D;
        border-radius: 22px;
        padding: 29px 43px;
        @media ($sm-1679){
            padding: 16px;
            border-radius: 20px;
        }
        @media ($sm-1441){
            border-radius: 18px;
        }
        .service{
            color: #fff;
        }
        .descriptn{
            color: #fff;
       
            font-size: 12px;
            line-height: 23px;
            font-weight: 300;
        }
        .rate{
            color: #fff;
            font-size: 23px;
            line-height: 63px;
            font-weight: 600;
        }
        
    }


  
.modal-content{
    background-image: url(../images/lines.svg);
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #000;
}
.modal-body{
    border-bottom: 1px dashed #222222;
}

.modal-title,
.modal-desc{
    text-align: center;
}

.modal-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    @media ($sm-1679){
        font-size: 16px;
        line-height: 1.167;
    }
    @media ($sm-1441){
        font-size: 14px;
    }
    @media ($sm-1199){
        font-size: 13px;
    }
    
}

.footer-table{
    width: 100%;
    font-feature-settings: 'lnum' 1;
    td{
        border: none;
        background-color: #000;
        color: #fff
    }
    .transctn{
        // font: normal normal normal 14px/24px Barlow;
        letter-spacing: 0px;
        color: #6F6F6F;
        font-size: 14px;
        font-weight: 400;
    }
    .val{
        font-weight: 600;
        color: #fff;
        font-size: 14px;
    }
}
} 