
@import 'partials/partials';

.signup{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px;

    
    @media ($sm-1679){
        margin-top: 50px;
        margin-bottom: 80px;
    }
    @media ($sm-1441){
        margin-top: 50px;
        margin-bottom: 70px;
    }
    @media ($sm-1199){
        margin-top: 20px;
        margin-bottom: 60px;
    }
    @media($sm-991){
        margin-top: 0px;
        padding: 100px 0px  ;
    }
    @media($sm-767){
        margin-top: 0px;
        padding: 100px 0px  ;
    }
  
   
    .signup-wrp{
        max-width: 1050px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 70vh;
        .form-title{
            font-weight: 600;
            font-size: 24px;
            line-height: 1;
            color: $white;
            margin-bottom: 28px;
            @media ($sm-1679){
                font-size: 22px;
            }
            @media ($sm-1441){
                font-size: 20px;
            }
        }
    }
    .step-wrp{
        margin-bottom: 50px;
        font-feature-settings: 'lnum' 1;
        overflow: clip;
        max-width: 476px;
        width: 100%;
        .step-itm{
            &.active{
                background-color: transparent !important;
                border: none !important;
                .number{
                    border: 1px solid $primary;
                    .num{
                        background-color: $primary;
                        color: $white;
                        opacity: 1;
                    }
                    &:after{
                        background-color: $primary;
                    }
                }
                .title{
                    color: $primary;
                }
            }
            &:last-child{
                .number{
                    &:after{
                        display: none;
                    }
                }
            }
        }
        .number{
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $fadeBorder;
            width: 28px;
            height: 28px;
            border-radius: 50px;
            font-weight: 400;
            padding: 3px;
            position: relative;
            z-index: 3;
            backdrop-filter: blur(100px);
            margin-bottom: 15px;
            &:after{
                content: '';
                height: 1px;
                background-color: $fadeBorder;
                position: absolute;
                left: 100%;
                right: -132px;
                z-index: 1;
                @media (max-width:440px){
                    right: -115px;
                }
                @media (max-width:380px){
                    right: -97px;
                }
            }
            .num{
                background-color: $fadePrimary;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                position: relative;
                z-index: 3;
                color: $white;
                opacity: 0.6;
               
            }
        }
        .title{
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            @media ($sm-579){
                font-size: 11px;
            }
            @media ($sm-479){
                min-height: 38px;
            }
        }
        .steps{
            display: flex;
            padding: 0;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 0px;
            .step-itm{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
            }
        }
        
    }
    .form-wrp{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        .spinner-border{
            top: 55%;
            position: absolute;
            @media ($sm-1679){
                top: 60%;
            }
            @media ($sm-1199){
                top: 51%;
            }
            @media ($sm-479){
                top: 44%;
            }
        }
        .form-steps{
            width: 100%;
            &.step-1{
                .row{
                    margin-left: -15px;
                    margin-right: -15px;
                }
                [class^='col-']{
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            .form-control{
                border:1px solid $signupFormBorder;
                background-color: $darkInputBg !important;
                border-radius: 29px;
                color: $white;
                &::placeholder{
                    color: #585858;
                    opacity: 1;
                }
                &::-moz-placeholder{
                    color:#585858;
                    opacity: 1;
                }
                &::-webkit-placeholder{
                    color: #585858;
                    opacity: 1;
                }
            }
            &.step-1, &.step-3{
                .forms-fields{
                    padding-left: 110px;
                    padding-right: 110px;
                    @media ($sm-1679){
                        padding-left: 100px;
                        padding-right: 100px;
                    }
                    @media ($sm-1441){
                        padding-left: 90px;
                        padding-right: 90px;
                    }
                    @media ($sm-1199){
                        padding-left: 50px;
                        padding-right: 50px;
                    }
                    @media ($sm-991){
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                    @media ($sm-767){
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    .coupn-wrp{
                        display: flex;
                        @media ($sm-479){
                            flex-direction: column;
                        }
                        .btn{
                            padding: 10px 20px;
                            height: 52px;
                            max-width: 100px;
                            width: 100%;
                            margin-left: 15px;
                            border-radius: 29px;
                            @media ($sm-479){
                                max-width: 100%;
                                margin-left: 0px;
                                height: 48px;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
            &.step-3{
                .forms-fields{
                    max-width: 1050px;
                    margin: 0 auto;
                    padding-left: 0px;
                    padding-right: 0px;
                    position: relative;
                }
                .inner-title{
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 36px;
                    color: $white;
                }
                .row{
                    margin-left: -36px;
                    margin-right: -36px;
                    @media ($sm-1679){
                        margin-left: -30px;
                        margin-right: -30px;
                    }
                    @media ($sm-1441){
                        margin-left: -20px;
                        margin-right: -20px;
                    }
                }
                [class^='col-']{
                    padding-left: 36px;
                    padding-right: 36px;
                    @media ($sm-1679){
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                    @media ($sm-1441){
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                .form-control{
                    background-color: $darkInputBg !important;
                }
                .trans-blk{
                    position: relative;
                    // max-height: 480px;
                    // overflow-y: auto;
                    overflow-x: hidden
                    ;
                    padding-right: 20px;
                    &::after{
                        // content: "";
                        width: 2px;
                        top: 28px;
                        bottom: 0px;
                        background-color: #222;
                        position: absolute;
                        right: -12px;
                        @media ($sm-767){
                            display: none;
                        }
                    }
                    // &::-webkit-scrollbar-thumb
                    // {
                    //     background-color: #000000;
                    //     border: 2px solid #555555;
                    // }
                    //                     &::-webkit-scrollbar {
                    //     width: 0.5px !important; 
                    // }
                
                    // &::-webkit-scrollbar-track {
                    //     background-color: #222 !important; 
                        
                    // }
                
                    // &::-webkit-scrollbar-thumb {
                    //     background-color: #F42F2F !important; 
                    // }
                
                   
                    // scrollbar-width: thin !important; ;  
                    // scrollbar-color:#F42F2F #222  !important; 
                    .text-sec{
                        min-height: 54px !important;
                        &::placeholder{
                            padding-top: 7px;
                        }
                    }

                    
                }
                .expiry-field{
                    display: flex;
                    justify-content: space-between;
                    @media($sm-579){
                        flex-wrap: wrap;
                    }
                    .input-wrp{
                        flex: 0 0 46%;

                        @media($sm-579){
                            width: 100%;
                            flex: 0 0 100%;
                        }
                    }
                }
            }
        }
    }
    // plan cards
.plan-card-checkbox{
    position: relative; 
    padding-top: 20px;
    @media($sm-991){
        padding-bottom: 20px;
    }
    &:hover,
    &.active{
        .plan-card{
        border: 1px solid $primary;
        background: transparent linear-gradient(180deg, #121212 0%, #2C0B0B 100%);
        }
    }
    .custom-chk{
        .radio{
            background-color: #171717;
            margin-right: 0;
            width: 22px;
            height: 22px;
            position: absolute;
            top: 22px;
            right: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after{
                display: none;
            }
        }

        .custom-chk-inp{
            &:checked{
                & ~ .radio{
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                    background-position: center;
                    background-size: 70%;
                    background-repeat: no-repeat;
                    background-color: $primary;
                }
                & ~ .plan-card{
                    border: 1px solid $primary;
                    background: transparent linear-gradient(180deg, #121212 0%, #2C0B0B 100%);
                }
            }
        }
    }
}


.plan-card{
    padding: 49px 37px;
    border: 1px solid transparent;
    border-radius: 22px;
    transition: all ease-in-out 0.02s;
    background: transparent linear-gradient(180deg, #121212 0%, #000000 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 565px;
    @media($sm-1679){
        min-height: 500px;
    }
    @media ($sm-991){
        margin-bottom: 20px;
        min-height: 400px;
    }
    

    .plan-head{
        padding: 0px;
        position: relative;
        .plan-titles{
            display: flex;
            align-items: center;
            justify-content: space-around;
        } 
        .service{
            font-size: 25px;
            font-weight: 600;
            color: $white;
            margin-bottom: 0px;
            text-align: left;
            @media ($sm-1679){
                font-size: 23px;
            }
            @media ($sm-1441){
                font-size: 21px;
            }
            .icon{
                margin-right: 12px;
                img{
                    max-width: 26px;
                    @media ($sm-1679){
                        max-width: 22px;
                    }
                }
            }
            
        }
        .short-desc{
            font-size: 14px;
            text-align: left;
            line-height: 23px;
            font-weight: 300;
            color: $white;
            margin-top: 18px;
            margin-bottom: 23px;
            @media ($sm-1679){
                margin-top: 14px;
                margin-bottom: 18px;
            }
        }
        .rate{
            font-size: 35px;
            font-weight: 600;
            line-height: 1;
            color: $white;
            border-bottom: 1px solid #191919;
            padding-bottom: 18px;
            text-align: left;
            @media ($sm-1679){
                font-size: 32px;
            }
            @media ($sm-1441){
                font-size: 28px;
            }
            .billcycle{
                font-size: 13px;
                font-weight: 400;
                color: $white;
            }
        }
    } 

    .plan-info-list{
        padding-top:20px;
        padding-left: 0px;
        @media ($sm-1679){
            padding-top: 15px;
        }
        @media ($sm-1199){
            padding-top: 10px;
        }
        .list-itm{
            display: flex;
            align-items:self-start;
            justify-content: flex-start;
            font-size: 12px;
            font-weight: 300;
            color: $white;
            font-weight: 500;
            line-height: 1.5;
            margin-bottom: 20px;
            @media ($sm-1679){
                margin-bottom: 16px;
            }
            @media ($sm-1441){
                margin-bottom: 14px;
            }
            @media ($sm-1199){
                margin-bottom: 12px;
            }
            .txt-inner{
                text-align: left;
                flex: 1;
                padding-left: 10px;
                margin-top: -6px;
                .txt{
                    padding-left: 0px;
                    &.title{
                        font-size: 13px;
                        line-height: 23px;
                    }
                }
            }
            .ico{
                display: flex;
                align-items: center;
                padding-top: 0px;
                color: $primary;
                img{
                    width: 16px;
                    height: 12px;  
                }
                svg{
                    width: 10px;
                    height: 12px;
                }
            }
            .txt{
                padding-left: 8px;
            }
        }
    }
}
    .signup-action-footer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        width: 100%;
        margin-top: 50px;
        @media ($sm-1679){
            margin-top: 40px;
        }
        @media ($sm-1441){
            margin-top: 30px;
        }
        @media ($sm-1199){
            margin-top: 20px;
        }
        @media (max-width:480px){
            flex-direction: column;
            margin-top: 85px;
        }
        .actn-arrows{
            width: 100%;
            max-width: 200px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            line-height: 1;
            font-weight: 400;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 40px;
            padding-right: 30px;
            min-height: unset;
            @media ($sm-1679){
                font-size: 18px;
                padding-left: 30px;
                padding-right: 20px;
            }
            @media ($sm-1441){
                font-size: 16px;
            }
            @media ($sm-1199){
                font-size: 14px;
            }
            @media (max-width:480px){
                max-width: 100%;
                margin-right: 0px;
                margin-left: 0px;
                margin-bottom: 20px;
            }
            img{
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
            &.next{
                background-color: $primary;
                border-color: $primary;
                .ico{
                    transform: rotate(90deg);
                }
                @media ($sm-579){
                    margin-left: 20px;
                }
                @media (max-width:480px){
                    margin-left: 0px;
                }
            }
            &.prev{
                background-color: #00000065;
                border-color: #222222;
                color: $white;
                padding-left: 30px;
                padding-right: 40px;
                position: relative;
                z-index: 1;
               
                .ico{
                    transform: rotate(270deg);
                }
                &.back-hm{
                    max-width: 240px;

                    @media($sm-579){
                        max-width: 100%;
                    }
                }
            }
            &:hover{
                background-color: $primary;
                border-color: $primary;
            }
    
        }
        .signup-txt{
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
        color: $white;
        @media ($sm-1679){
            font-size: 14px;
        }
        @media ($sm-1441){
            font-size: 13px;
        }
        @media ($sm-1199){
            font-size: 12px;
        } 
        @media (max-width:480px){
            margin-bottom: 20px;
        }
    }
    .signup-txt{
        text-align: center;
    }
    }
}

.signup-action-footer-inner{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    height: unset;
    .sep-wrp{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 35px;
        margin-top: 20px;
        &:before,
        &:after{
            display: block;
            content: '';
            height: 1px;
            background-color: #FFFFFF;
            flex: 1;
            opacity: 0.2;
        }
        
        .sep-title{
            margin-bottom: 0;
            font-size: 14px;
           
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .signup-txt,
    .login-txt{
 
        font-size: 16px;
        margin-bottom: 0;
        @media ($sm-1679){
            font-size: 14px;
        }
        @media ($sm-1441){
            font-size: 13px;
        }
        @media ($sm-1199){
            font-size: 12px;
        } 
    }
    .signup-txt{
        text-align: center;
        color: #ffffff;
    }
    .btn-google-login{
        color: #ffffff !important;
        border: 1px solid #333333 !important;
        border-radius: 30px;
        background-color: #171717 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        min-width: 530px;
        margin: 0 auto;
        // margin-bottom: 20px;
        @media($sm-767){
            width: 100%;
            min-width: 100%;
        }
        
        .ico{
            img{
                width: 22px;
                object-fit: contain;
            }
        }
        .txt{
            padding-left: 24px;
        }
    }
}
.coupon-msg img{
    margin-right: 5px;
}
.coupon-msg{
    font-size: 12px;
}
.freetrail-text{
    background-color: #F42F2F;
    color: #fff;
    padding: 10px;
    position: absolute;
    top: -20px;
    left: 20px;
    width: 200px;
    border-radius: 20px;
    font-size: 14px;
    border: none;
    box-shadow: none;
    // line-height: 23px;
    font-weight: 500;
    color: $white;

    @media ($sm-1679){
  
    }
}

.sep-wrp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #fff;
}
.sep-wrp:after, .sep-wrp:before {
    display: block;
    content: "";
    height: 2px;
    background-color:red;
    flex: 1 1;
    opacity: 0.2;
}
.sep-title {
    margin-bottom: 0;
    // font-size: 14px;
    font-weight: 600;
    padding-left: 24px;
    font-size: 20px;
    color: #fff;
    padding-right: 24px;
}
.sep-msg{
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    align-items: center;
    color: #FFF;
    a{
        text-decoration: none;
        font-size: 16px;
        padding-left: 5px;
        cursor: pointer;
        color:#F42F2F!important;
    }
}
.code-btn{
    background-color: transparent !important;
    color:#F42F2F!important;
}