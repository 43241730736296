@import 'partials/partials';

body,html,*{
    font-family: $primaryFont !important;
}
body{
    background-color: $bodyBg;
}
.custom-container{
    max-width: 1400px;
    @media ($sm-1679){
        max-width: 1300px;
    }
    @media ($sm-1441){
       padding-left: 50px;
       padding-right: 50px;
    }
    @media ($sm-991){
        padding-left: 30px;
        padding-right: 30px;
    }
    @media ($sm-767){
        padding-left: 20px;
        padding-right: 20px;
    }
} 

img{
    max-width: 100%;
}
 

// forms and buttons
.btn.btn{
    line-height: 1;
    font-size: 16px;
    padding: 16px;
    min-height: 58px;
    border-radius: 40px;
    @media ($sm-1679){
        font-size: 14px;
        padding: 14px;
        min-height: 52px;
    }
    @media ($sm-1441){
       
        padding: 13px;
        min-height: 48px;
    }
    &-primary{
        background-color: #F42F2F;
        border-color:#F42F2F;
        &:hover,
        &:focus,
        &:active{
            background-color: #d12929;
            border-color: #d12929;  
        }
    }
    &-secondary{

    }
}

.input-wrp{
    margin-bottom: 30px; 
    position: relative;
    
    @media ($sm-1679){
        margin-bottom: 26px; 
    }
    @media ($sm-1441){
        margin-bottom: 20px; 
    }
    @media ($sm-1199){
        margin-bottom: 18px; 
    }
    label{
        
        &.label{
            color: #FFFFFF;
            margin-bottom: 8px;
            font-size: 16px;
            @media ($sm-1679){
                margin-bottom: 4px;
                font-size: 13px;
            }
            @media ($sm-1441){
                margin-bottom: 2px;
                font-size: 12px;
            }
            @media ($sm-1199){
                margin-bottom: 0; 
            } 
            &.req{
                &:after{
                    content: '*';
                    color: #f00;
                    margin-left: 3px;
                    font-size: 0.8em;
                }
            }
            .tip-icon{
                max-width: 11px;
                margin-left: 6px;
                cursor: pointer;

            }
        }
    }
}

// error message
.form-error{
    position: absolute;
    top: 100%;
    color: #f00;
    font-size: 11px;
}

.custom-chk{
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    .custom-chk-inp{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        inset: 0;
        &:checked{
            & ~ .chk{
                background-color: #171717;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                background-position: center;
                background-size: 70%;
                background-repeat: no-repeat;
            }
            & ~ .radio{
                background-color: #171717;
                background-position: center;
                background-size: 70%;
                background-repeat: no-repeat;
                width: 23px;
                height: 23px;
                border-radius: 23px;
                &::after{
                    content: "";
                    width: 11px;
                    height: 11px;
                    border-radius: 11px;
                    background-color: var( --bgWhite);
                    position: absolute;
                    top: 7px;
                    left: 7px;
                }
            }
        }
    }

    span.chk{
        width: 24px;
        height: 24px;
        background-color: #171717;
        border-radius: 4px;
        margin-right: 10px;
    }
    span.radio{
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 10px;
        &::after{
            content: "";
            background-color: #171717;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            position: absolute;
            top: 7px;
            left: 7px;
        }
    }
    
}

.password-field-wrp{
    position: relative;
    .form-control{
        padding-right: 50px;
    }
    .show-hide{
        border: 0; 
        outline: none;
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .hide{
            display: none;
        } 
        .show{
            display: block;
        } 
        &.hidden{
            .hide{
                display: block;
            }
           .show{
            display: none;
           } 
        }
        
        img{
            width: 50%;
            object-fit: contain;
        }
    }
}

textarea.form-control{
    border-radius: 12px;
    min-height: 72px !important;
    padding-top: 10px;
    padding-bottom: 10px;
}
.form-control{
    border: 1px solid #333333;
    border-radius: 40px;
    font-size: 16px;
    padding: 5px 24px;
    line-height: 1;
  
    color: #FFFFFF !important;
    min-height: 58px;
    background-color: #00000080 !important;
    @media ($sm-1679){
        font-size: 14px;
        padding: 5px 20px;
        min-height: 52px;
    }
    @media ($sm-1441){
        font-size: 13px;
        padding: 5px 18px;
        min-height: 48px;
    }
    &:focus,
    &:active{
        box-shadow: none !important;
        border: 1px solid #333333;
    }
    &::placeholder{
        color: #585858;
        opacity: 0.5;
    }
}
 

// links
.links{
    
    &-primary{
        color: #F42F2F;
        &:hover{
            color: #d12929;
        }
    }
}
// custom-switches

.custom-switch {
    cursor: pointer;
    height: 20px;
  
    .switch {
      display: inline-block;
      height: 20px;
      position: relative;
      width: 36px;
      margin: 0;
  
      input {
        display: none;
  
        &:checked {
          & + .slider {
            background-color: #f42f2f;
            border: none;
  
            &:before {
              transform: translate(26px);
              background: #fff;
              left: -7px;
            }
          }
        }
      }
  
      .slider {
        background-color: #171717;
        // border: 2px solid var(--switchOffBg);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
  
        &:before {
          background-color: #171717;
          bottom: 3px;
          content: '';
          height: 14px;
          left: 3px;
          position: absolute;
          transition: 0.4s;
          width: 14px;
        }
  
        &.round {
          border-radius: 34px;
  
          &:before {
            border-radius: 50%;
          }
        }
      }
    }
  }

// scrollbar

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
   
*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }
   
*::-webkit-scrollbar-thumb {
    background-color: #f42f2f;
    outline: 1px solid #f42f2f;
  }

  .text-danger {
    position: absolute;
  }

  input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.message1{
    text-align: center;
 
    width: 100%;
    display: flex;
    justify-content: center;
    color: #585858;
}
.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    background: none;
    border: none;
    color: #ffff;
    outline: none;
    &::placeholder{
        color: #585858; 
    }
    &:focus{
        border: none;
    }
    &:focus-within{
        border: none;
    }
    &:focus-visible{
        border: none;
    }
}
.PhoneInputCountrySelect {

    background-color: #000 !important;
    color: #ffff !important;
   
} 
.css-yk16xz-control {
    background-color: transparent !important;
    border: none !important;
}
.css-26l3qy-menu {
    background-color: #000 !important;
}
.css-1n7v3ny-option ,.css-9gakcf-option {
    background-color: #F42F2F !important;
}
.css-9gakcf-option {
    background-color: #F42F2F !important;
}
.css-1uccc91-singleValue {

    color: #fff !important;
}
.css-1pahdxg-control{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.css-1wa3eu0-placeholder {
    color: #fff !important;
}

label.p-FieldLabel {
    color: #fff !important;
}

.modal-backdrop.show {
    // opacity: var(--bs-backdrop-opacity);
    background: #3C3C3C3E;
}

// .btn.btn-primary.actn-arrows.pay-btn{
//     max-width: 200px;
//     height: 58px;
//     background: #F42F2F 0% 0% no-repeat padding-box;
//     border-radius: 29px;
//     margin: 20px 0px;
// }
.form-control.custom-select{
    padding-top: 10px;
}
.btn.btn-primary.actn-arrows.pay-btn {
    width: 100%;
    max-width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 30px;
    min-height: unset;
    margin:20px 0px;
    @media ($sm-1679){
        font-size: 18px;
        padding-left: 30px;
        padding-right: 20px;
    }
    @media ($sm-1441){
        font-size: 16px;
    }
    @media ($sm-1199){
        font-size: 14px;
    }

    @media (max-width:480px){
        max-width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 20px;
        font-size: 14px;
    }
}
.Toastify__toast {

    background: #171717 0 0 no-repeat padding-box !important;
    border-radius: 26px;
    min-height: 54px;
    padding: 0 10px;
    border-radius: 10px !important;
    display: flex;
    border-radius: 29px;
    align-items: center;
    justify-content: center;
}

.Toastify__progress-bar--animated{
    display: none !important;
  
    
}

.Toastify__close-button--light {
    color: #000;
    opacity: 1;
    top: 17px;
    bottom: 0;
    position: absolute;
    right: 13px;
  
}
.toast-body{
    .toast-content-sec{
        width: 77%;
    }
    .icon-wrp {
        background: #48A04B;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: 10px;
        &.error-toast{
            background: #F32F2F;  
        }
        img{
            // width: 30px !important;
            // height: 20px !important;
            object-fit: cover !important;
            width: 20px;
      
}
    }

    h5.title{
        text-align: left;
     
        letter-spacing: 0px;
        color: #FFFFFF;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
    }
    .sm-txt{
        margin-bottom: 0px !important;
        text-align: left;
     
        letter-spacing: 0px;
        color: #fff;
        font-weight: 300;
        font-size: 13px;
        line-height: 19px;
    }
}

.Toastify__close-button>svg {
   
    fill: #fff !important;
}
.Toastify__close-button--light {
    color: #000;
    opacity: 1;
    top: 15px !important;
}
.payment-sec{
    width: 100%;
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: -118px;
    right: 0;
    .amt-sum-blk{
        position: absolute;
        top: -30px;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
    }
    .btn{
        height: 49px;
    }

    @media($sm-1679){
        bottom: -100px;
    }
    @media($sm-1481){
        bottom: -90px;
    }
    @media($sm-1281){
        bottom: -89px;
    }

}
.custom-select {
   
    background:url("../images/download-select.svg") no-repeat right 18px center !important;
}
.loader-inner{
    display: flex;
    justify-content: center;
    min-height:50vh;
    align-items: center;
}
.spinner-border{
    height: 70px;
    width: 70px;

    @media($sm-1679){
        width: 60px;
        height: 60px;
    }
}
.v-txt{
    display: flex;
    justify-content: center;
    align-items: center;
    .drd-txt{
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        span{
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
}